import React, { useState, useEffect } from "react";
import FlightList from "../components/FlightList";
import { useFlightData } from "../data/FlightData";
import { Alert } from "reactstrap";
import "./MyFlights.css";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../utils/useLocalStorage";

function MyFlights() {
  const [flightData, setFlightData] = useState([]);
  const [flightid, setFlightId] = useState(0);
  const [isFlightDeleted, setFlightDeleted] = useState(false);
  const { getMyFlights, deleteFlight } = useFlightData();
  const [user] = useLocalStorage("auth", {});
  const history = useHistory();

  useEffect(() => {
    if (typeof user.userid !== "undefined") {
      GetMyFlights();
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (flightid > 0) {
      DeleteFlightData();
      setFlightId(0);
    }
  }, [flightid]);

  async function GetMyFlights() {
    const flightsdata = await getMyFlights(user.mobile);
    setFlightData(flightsdata);
  }

  async function DeleteFlightData() {
    const response = await deleteFlight(user.mobile, flightid);
    GetMyFlights();
    setFlightDeleted(true);
  }

  return (
    <div>
      {isFlightDeleted && (
        <Alert className="alertYellow">Flight Deleted Successfully.</Alert>
      )}
      <FlightList
        flightData={flightData}
        setFlightId={setFlightId}
        page="MyFlight"
      />
    </div>
  );
}

export default MyFlights;
