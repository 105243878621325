import React, { useState, useEffect, useRef } from "react";
import Ticker from "react-ticker";
import LoginAndSignUp from "../components/LoginAndSignUp";
import { useHistory, Link, useParams } from "react-router-dom";
//const [user, setUser] = useLocalStorage("auth", {});
import { useLocalStorage } from "../utils/useLocalStorage";
import { useFlightData } from "../data/FlightData";
import { authAndUserData } from "../data/AuthAndUserData";
import {
  FaUserCircle,
  FaPlaneDeparture,
  FaHome,
  FaUsers,
  FaUserFriends,
  FaHamburger,
  FaIndent,
  FaBars,
  FaQuestionCircle,
  FaFile,
  FaPhone,
  FaQuestion,
  FaShieldAlt,
  FaNewspaper,
} from "react-icons/fa";
import { SiChatbot } from "react-icons/si";
import { IoChatbubbleOutline, IoStar } from "react-icons/io5";
import { BsInfoCircleFill } from "react-icons/bs";
import UserImage from "../assets/images/icons/ninja3.png";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import PlansPopup from "../pages/PlansPopup";
import { Badge } from "antd";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Header() {
  const [user, setUser] = useLocalStorage("auth", {});
  const [bulletindata, SetBulletinData] = useState([]);
  const [lastIndex, SetLastIndex] = useState(0);
  const [userImage, SetUserImage] = useState(UserImage);
  const [openmode, SetOpenMode] = useState(null);
  const [openmodal, SetOpenModal] = useState(null);
  const [collapseOpen, SetCollapseOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [badgeNumberOfchat, setBadgeNumberOfchat] = useState(0)
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);
  const [googleAccessToken, SetToken] = useLocalStorage(
    "googleAccessToken",
    {}
  );
  const { pathname } = useLocation();
  let history = useHistory();

  const collapseButton = useRef(null);
  //console.log(collapseButton);

  const { GetBulletin } = useFlightData();
  const { GetUserDetailsForHome } = authAndUserData();
  const { key } = useParams();
  useEffect(() => {
    console.log("Location: " + window.location.pathname);
    GetBulletinString();
    if (user.userid != undefined) {
      GetUserDetailsonHomePage();
    }

    if (key == "Login") {
      SetOpenModal(true);
      SetOpenMode("Login");
    } else if (key == "SignUp") {
      SetOpenModal(true);
      SetOpenMode("SignUp");
    }

    if (user.profile_pic_path === null || user.profile_pic_path === "") {
      SetUserImage(userImage);
    } else {
      SetUserImage(user.profile_pic_path);
    }
    if (user.is_active === false) {
      //window.location.href = 'https://pay.flykatana.com/checkout.html?email='+user.email+'&name='+user.name;
    }
  }, [user]);

  if (key == "logout") {
    setUser({});
    SetToken({});
    window.location = "/";
  }

  async function GetBulletinString() {
    const bulletin = await GetBulletin();
    SetBulletinData(bulletin);
  }

  function RedirectToHome() {
    window.location = "/";
  }

  async function GetUserDetailsonHomePage() {
    const userData = await GetUserDetailsForHome(user.userid);
    setBadgeNumberOfchat(userData.unread_messages)
    // console.log("User Data: " + JSON.stringify(userData));
    // console.log("user Check", user.userid);
    // if (userData.is_new === true && userData.logged_in_times == 1) {
    //   let path
    //   if (localStorage?.getItem("Inquire")) {
    //     path = "/"
    //     localStorage?.removeItem("Inquire")
    //   } else {
    //     path = `/plans`;
    //   }
    //   history.push(path);
    // }
    //OLD SUBSCRIPTION LOGIC CHANGED ON 9 MAR 2023
    // if(userData.is_active === false && userData.is_new === true)
    // {
    //   window.location.href = 'https://pay.flykatana.com/checkout-new.html?email='+user.email+'&name='+user.name+'&userid='+user.userid;
    // }
    // else if(userData.is_active === false && key != "logout" && userData.is_new != true)
    // {
    //   var logout_url = String(window.location);
    //   var url = logout_url.split("/home")[0];
    //   window.location.href = 'https://pay.flykatana.com/checkout.html?email='+user.email+'&name='+user.name+'&userid='+user.userid+'&url='+url;
    // }
  }

  const LogOut = (event) => {
    setUser({});
    SetToken({});
    window.location.href = "/";
  };

  function GotoProfile(e) {
    window.location = "/myProfile";
  }

  return (
    <>
      <PlansPopup
        show={showPopup}
        handleClose={handleClosePopup}
        closeOnClick={true}
      />
      <div
        id="top-header"
        style={{ position: "fixed", zIndex: "1", width: "100%", top: "0" }}>
        <div className="block bg-dark">
          {openmodal && openmode && (
            <LoginAndSignUp openmodal={openmodal} openmode={openmode} />
          )}
          {bulletindata && bulletindata.length > 0 && (
            <Ticker>
              {({ index }) => (
                <>
                  {lastIndex + 1 == bulletindata.length
                    ? SetLastIndex(0)
                    : SetLastIndex(lastIndex + 1)}
                  <h5
                    className={
                      bulletindata[lastIndex].live
                        ? "text-yellow"
                        : "text-white"
                    }>
                    &nbsp;
                    {bulletindata[lastIndex].from_airport +
                      " - " +
                      bulletindata[lastIndex].to_airport +
                      " $" +
                      bulletindata[lastIndex].total_price +
                      " |"}
                  </h5>
                </>
              )}
            </Ticker>
          )}
        </div>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark clearfix"
          expand="md"
          style={{ backgroundColor: "rgb(255, 230, 0)" }}>
          <div style={{ width: "25%" }}>
            <NavbarBrand
              to="/" //onClick ={RedirectToHome}
              tag={Link}>
              <img
                alt="..."
                style={{ height: "50px" }}
                src={require("../assets/images/Brandnew.png").default}
              />
            </NavbarBrand>
          </div>
          {typeof user.userid === "undefined" ? (
            <div style={{ width: "75%" }} className="text-right flex-inline">
              <button
                className="navbar-toggler"
                id="navbar-collapse-main"
                onClick={(e) => {
                  SetCollapseOpen(true);
                }}>
                <FaBars
                  className="text-dark"
                  style={{ fontSize: "35px" }}
                  onClick={(e) => {
                    SetCollapseOpen(true);
                  }}
                />
              </button>
              <UncontrolledCollapse
                navbar
                toggler="#navbar-collapse-main"
              // defaultOpen={true}
              // isOpen={collapseOpen}
              >
                <div className="navbar-collapse-header d-md-none">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link
                        to="/"
                        onClick={(e) => {
                          SetCollapseOpen(false);
                        }}>
                        <img
                          alt="..."
                          src={require("../assets/images/Brandnew.png").default}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button
                        className="navbar-toggler"
                        id="navbar-collapse-main"
                        ref={collapseButton}>
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-auto text-center" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>Home</b>
                      </h3>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/How-to"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>How To</b>
                      </h3>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/press"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>Press</b>
                      </h3>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/About"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>About</b>
                      </h3>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/Testimonials"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>Testimonials</b>
                      </h3>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/legal"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>Legal</b>
                      </h3>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link text-dark"
                      tag={Link}
                      to="/contactus"
                      onClick={(e) => {
                        collapseButton.current.click();
                      }}>
                      <h3>
                        <b>Contact</b>
                      </h3>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className="mt-2"
                    onClick={(e) => {
                      collapseButton.current.click();
                    }}>
                    <LoginAndSignUp />{" "}
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </div>
          ) : (
            <div style={{ width: "75%" }} className="text-right flex-inline">
              <img
                src={userImage}
                color="dark"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={(e) => GotoProfile(e)}></img>{" "}
              &nbsp;&nbsp;&nbsp;
              <Badge style={{ margin: '5px' }} count={badgeNumberOfchat}>
                <a style={{ color: 'black' }} href="/chat" >
                  <IoChatbubbleOutline style={{ fontSize: '30px', marginTop: '5px' }} />
                </a>
              </Badge>
              &nbsp;&nbsp;
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <FaBars className="text-dark" style={{ fontSize: "35px" }} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/" className={`${pathname === '/' && 'useactive'}`} tag={Link}>
                    <FaHome />
                    <span>Home</span>
                  </DropdownItem>
                  <DropdownItem to="/myProfile" className={`${pathname === '/myProfile' && 'useactive'}`} tag={Link}>
                    <FaUserCircle />
                    <span>Profile</span>
                  </DropdownItem>
                  <DropdownItem to="/myFlights" className={`${pathname === '/myFlights' && 'useactive'}`} tag={Link}>
                    <FaPlaneDeparture />
                    <span>My Flights</span>
                  </DropdownItem>
                  {/* Chat  */}

                  <DropdownItem to="/chat" className={`${pathname === '/chat' && 'useactive'}`} tag={Link}>
                    <SiChatbot />
                    <span>Chat</span>
                  </DropdownItem>
                  {/* Chat  */}

                  {user.subscription_plan_id != 1 ? (
                    <>
                      <DropdownItem to="/myFriends" className={`${pathname === '/myFriends' && 'useactive'}`} tag={Link}>
                        <FaUserFriends />
                        <span>Friends</span>
                      </DropdownItem>
                      <DropdownItem to="/myGroups" className={`${pathname === '/myGroups' && 'useactive'}`} tag={Link}>
                        <FaUsers />
                        <span>Groups</span>
                      </DropdownItem>
                    </>
                  ) : (
                    <>
                      <DropdownItem to="/myFriends" onClick={handleShowPopup}>
                        <FaUserFriends />
                        <span>Friends</span>
                      </DropdownItem>
                      <DropdownItem to="/myGroups" onClick={handleShowPopup}>
                        <FaUsers />
                        <span>Groups</span>
                      </DropdownItem>
                    </>
                  )}
                  <DropdownItem to="/How-to" className={`${pathname === '/How-to' && 'useactive'}`} tag={Link}>
                    <FaQuestionCircle />
                    <span>How-To</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/Testimonials" className={`${pathname === '/Testimonials' && 'useactive'}`} tag={Link}>
                    <IoStar />
                    <span>Testimonials</span>
                  </DropdownItem> */}
                  {/* <DropdownItem to="/empty-legs" className={`${pathname === '/empty-legs' && 'useactive'}`} tag={Link}>
                    <IoStar />
                    <span>Empty Legs</span>
                  </DropdownItem> */}
                  <DropdownItem to="/About" className={`${pathname === '/About' && 'useactive'}`} tag={Link}>
                    <BsInfoCircleFill />
                    <span>About</span>
                  </DropdownItem>
                  <DropdownItem to="/press" className={`${pathname === '/press' && 'useactive'}`} tag={Link}>
                    <FaNewspaper />
                    <span>Press</span>
                  </DropdownItem>
                  <DropdownItem to="/legal" className={`${pathname === '/legal' && 'useactive'}`} tag={Link}>
                    <FaShieldAlt />
                    <span>Legal</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={LogOut}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>{" "}
            </div>
          )}
        </Navbar>
      </div>
    </>
  );
}

export const myElementRef = () => {
  //console.log("Tick height: "+document.getElementById("top-ticker").offsetHeight);
  return document.getElementById("top-header").offsetHeight; //+ document.getElementById("top-ticker").offsetHeight ;
};

export default Header;
