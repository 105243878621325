import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { authAndUserData } from "../../data/AuthAndUserData";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { Input, FormControl, InputLabel, FormGroup } from "@material-ui/core";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from "reactstrap";
import LoginImage from "../../assets/images/icons/ninja2.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Login({ setMode }) {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const { PostAuth } = authAndUserData();
  const [user, setUser] = useLocalStorage("auth", {});
  const [rememberme, SetRememberMe] = useLocalStorage("rememberme", {});
  const [chkRemember, SetCheckRemember] = useState(false);
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (typeof rememberme?.rememberMobile !== "undefined") {
      SetCheckRemember(true);
      console.log(JSON.stringify(rememberme));

      // Remove '+' or '++' from the number
      const cleanedMobile = rememberme.rememberMobile.replace(/\++/g, '');

      setMobile(cleanedMobile);
      setPassword(rememberme.rememberPsssword);
    }
  }, []);

  async function OnLogin(event) {
    console.log(mobile);
    if (ValidateData()) {
      if (chkRemember) {
        const RememberMe = {
          rememberMobile: mobile,
          rememberPsssword: password,
        };
        SetRememberMe(RememberMe);
      } else {
        SetRememberMe({});
      }
      const response = await PostAuth(parseInt(mobile), password);

      if (response) {
        setUser(response);
        setIsError(false);
        setErrorMessage("");
        if (localStorage?.getItem('from') && localStorage?.getItem("to") && localStorage?.getItem("Inquire")) {
          let date = localStorage?.getItem('date')
          if (date) {
            window.location = `/routes/${localStorage?.getItem('from')}-${localStorage?.getItem("to")}?date=${date}`;
            localStorage?.removeItem('date')
          } else {
            window.location = `/routes/${localStorage?.getItem('from')}-${localStorage?.getItem("to")}`;

          }
          localStorage?.removeItem("to")
          localStorage?.removeItem("from")
          localStorage?.removeItem("Inquire")
        } else {
          localStorage?.removeItem("to")
          localStorage?.removeItem("from")
          window.location = "/";
        }
      } else {
        setIsError(true);
        setErrorMessage("Please Enter Valid Credentials");
      }
    }
  }

  function OnSignUp(event) {
    setMode("SignUp");
  }

  function ValidateData() {
    if (mobile === "" || mobile === undefined || mobile === null) {
      setErrorMessage("Please Enter Mobile");
      setIsError(true);
      return false;
    } else if (password === "" || password === undefined || password === null) {
      setErrorMessage("Please Enter Password");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }

  return (
    <div>
      <div className="p-1 text-center">
        <img src={LoginImage} style={{ height: "auto", width: "40%" }} />
      </div>
      {isError && <Alert color="danger">{errorMessage}</Alert>}
      <FormGroup>
        <FormControl fullWidth className="mb-2">
          {/* <InputLabel htmlFor="mobile">(+1) Enter 10 digit mobile</InputLabel> */}
          <p style={{ color: "black", marginBottom: "0" }}>Mobile</p>

          <PhoneInput
            country={"us"}
            value={mobile}
            onChange={(phone) => setMobile(phone)}
          />
          {/* <Input
            id="mobile"
            type="number"
            value={mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10) setMobile(e.target.value);
            }}
          /> */}
        </FormControl>
        <FormControl fullWidth className="mb-2">
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </FormControl>
      </FormGroup>
      <div className="mb-3 text-right">
        <input
          type="checkbox"
          checked={chkRemember}
          onChange={(e) => SetCheckRemember(e.target.checked)}
        />
        &nbsp;&nbsp;&nbsp;
        <span className="text-muted">Remember me</span>
      </div>

      <div className="text-center mb-2">
        <Button
          name="login"
          style={{ borderRadius: "20px", backgroundColor: "rgb(255, 230, 0)" }}
          onClick={OnLogin}
          className="btn btn-block"
        >
          Log In
        </Button>
      </div>
      <div className="text-center mb-2">
        <b>
          <Link
            onClick={(e) => setMode("ForgotPassword")}
            style={{ color: "rgb(255, 230, 0)", fontSize: "15px" }}
          >
            Forgot Password
          </Link>
        </b>
      </div>
      <div className="text-center mb-3">
        <i style={{ fontSize: "15px" }}>Don't have an account?</i>
        &nbsp;&nbsp;&nbsp;
        <b>
          <Link onClick={OnSignUp} style={{ color: "black", fontSize: "15px" }}>
            FREE Sign Up
          </Link>
        </b>
      </div>
      <div className="text-center m-5">
        <b
          className="text-dark"
          style={{ fontWeight: "bolder", fontSize: "15px" }}
        >
          Katana helps members find like-minded travelers to split private
          planes & save money
        </b>
      </div>
      {/* <div className="text-center mb-3">
        <Button
          style={{
            borderRadius: "20px",
            backgroundColor: "rgb(255, 230, 0)",
          }}
          onClick={OnSignUp}
          className="btn btn-block"
        >
          Sign Up
        </Button>
      </div> */}
    </div>
  );
}

export default Login;
