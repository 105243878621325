import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";
import { useValidateData } from "../../data/Validation";
import { useGroupsData } from "../../data/GroupsData";
import ImportContactFromGoogle from '../GoogleContact/ImportContactFromGoogle';
import ImportContactFromOutlook from '../OutLookContact/ImportContactFromOutlook'
import PhoneInput from "react-phone-input-2";
import { FormControl } from '@material-ui/core';

function AddMember({ membersData, user, groupDetails, SetUser }) {
  const [mode, SetMode] = useState("");
  const [mobile, SetMobile] = useState("");
  const { CheckUserByMobile } = useValidateData();
  const { InviteGroupMember, AddGroupMember } = useGroupsData();
  const [isMember, SetIsMember] = useState(false);
  const [isSelfNumber, SetIsSelfNumber] = useState(false);
  const [isAdded, SetAdded] = useState(false);
  const [isInvited, SetInvited] = useState(false);

  useEffect(() => {
    if (mobile.length >= 11) {
      SetUserMode();
    } else {
      SetMode("");
    }
  }, [mobile]);

  async function ClickHandler(event) {
    console.log(groupDetails)
    const Body = {
      inviteename: user.name,
      mobile: user.mobile,
      invmobile: parseInt(mobile, 10),
      groupname: groupDetails[0].group_name,
      group_id: groupDetails[0].group_id,
    };

    if (event.target.name === "Add") {
      const response = await AddGroupMember(Body);
      SetAdded(true);
      setTimeout(function () {
        SetAdded(false);
      }, 3000);
      SetMode("");
      SetMobile("");
    } else if (event.target.name === "Invite") {
      const response = await InviteGroupMember(Body);
      SetInvited(true);
      setTimeout(function () {
        SetInvited(false);
      }, 3000);
      SetMode("");
      SetMobile("");
    }
  }

  async function SetUserMode() {
    if (AlreadyMember()) {
      SetIsMember(true);
      setTimeout(function () {
        SetIsMember(false);
      }, 3000);
    } else if (mobile == user.mobile) {
      SetIsSelfNumber(true);
      setTimeout(function () {
        SetIsSelfNumber(false);
      }, 3000);
    } else {
      const isUser = await CheckUserByMobile(mobile);
      if (isUser) {
        SetMode("Add");
      } else {
        SetMode("Invite");
      }
    }
  }

  function AlreadyMember() {
    const isMember = membersData.filter(
      (x) => x.mobile.toString() === mobile
    );
    if (isMember.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Card>
        {isMember && (
          <Alert className="m-2 alertYellow">
            Member already in your group, Try another number
          </Alert>
        )}
        {isAdded && (
          <Alert className="m-2 alertYellow">
            Your group member request send successfully
          </Alert>
        )}
        {isInvited && (
          <Alert className="m-2 alertYellow">Member invited successfully</Alert>
        )}
        {isSelfNumber && (
          <Alert className="m-2 alertYellow">
            You entered your own number, Try another number
          </Alert>
        )}
        <CardBody>
          <div className="text-center">
            <Row>
              <Col lg="4" md="4" sm="12">

                <FormGroup className="mb-3 form-inline">
                  {/* Bhim code start */}
                  <FormControl fullWidth className="mb-2">
                    <p style={{ textAlign: 'left', marginBottom: "0" }}>Mobile</p>

                    <PhoneInput
                      country={"us"}
                      value={mobile}
                      onChange={(phoneNumber) => {
                        if (phoneNumber.length >= 11) {
                          SetMobile(phoneNumber);
                        }
                      }}
                    />
                  </FormControl>
                </FormGroup>
                {/* <InputGroup
                  className="input-group-alternative"
                  style={{ width: "100%" }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-phone" />
                      &nbsp;&nbsp;&nbsp;(+1)
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    placeholder="Enter Member Mobile"
                    value={mobile}
                    onChange={(e) => {
                      e.target.value.length < 11 && SetMobile(e.target.value);
                    }}
                  />
                </InputGroup> */}
              </Col>
              {mode === "Add" && (
                <Col lg="2" md="2" sm="12">
                  <Button
                    className="mt-3 btn-block"
                    color="dark"
                    name="Add"
                    onClick={(e) => ClickHandler(e)}
                  >
                    Add Member
                  </Button>
                </Col>
              )}

              {mode === "Invite" && (
                <Col lg="2" md="2" sm="12">
                  <Button
                    className="mt-3 btn-block"
                    color="dark"
                    name="Invite"
                    onClick={(e) => ClickHandler(e)}
                  >
                    Invite Member
                  </Button>
                </Col>
              )}
              <Col lg="3" md="3" sm="12">
                <div className="mt-3">
                  <ImportContactFromGoogle
                    user={user}
                    SetUser={SetUser}
                    SetMobile={SetMobile}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="12">
                <div className="mt-3">
                  <ImportContactFromOutlook
                    user={user}
                    SetUser={SetUser}
                    SetMobile={SetMobile}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default AddMember;
