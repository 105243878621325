import React, { useEffect, useState } from "react";
import { Container, Row, Card, Alert } from "reactstrap";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useFriendsData } from "../data/FrindsData";
import FriendList from "../components/MyFriends/FriendList";
import AddFriend from "../components/MyFriends/AddFriend";
import InviteeList from "../components/MyFriends/InviteeList";
function MyFriends() {
  const [user, SetUser] = useLocalStorage("auth", {});
  const { GetAllFriend, RemoveFriendApi, LeaveGroup, InvitesSent } =
    useFriendsData();
  const [friendData, SetFriendData] = useState([]);
  const [inviteeData, SetInviteeData] = useState([]);
  const [removeFriendData, SetRemoveFriendData] = useState(null);

  useEffect(() => {
    GetMyFriend();
    GetInvites();
  }, []);

  async function GetInvites() {
    const response = await InvitesSent(user.userid);
    SetInviteeData(response);
    console.log(response);
  }

  async function GetMyFriend() {
    const response = await GetAllFriend(user.mobile);
    SetFriendData(response);
  }

  async function RemoveFriend() {
    console.log(removeFriendData);
    if(removeFriendData.is_group){

      const groupData = {
        memberid: removeFriendData.memberid,
      };
      const response = await LeaveGroup(groupData);
    }else{
    const friendsData = {
      mobile: user.mobile,
      friendnumber: removeFriendData.mobile,
    };
    const response = await RemoveFriendApi(friendsData);
  }
  GetMyFriend();
  SetRemoveFriendData(null);
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <div className="col p-0">
            <Card className="shadow">
              <AddFriend
                friendData={friendData}
                user={user}
                SetUser={SetUser}
              />
              <h2>&nbsp;&nbsp;&nbsp;My Friends</h2>
              <FriendList
                friendData={friendData}
                RemoveFriend={RemoveFriend}
                SetRemoveFriendData={SetRemoveFriendData}
              />
              <br />
              <h2>&nbsp;&nbsp;&nbsp;Invites Sent</h2>
              <InviteeList friendData={inviteeData} user={user} />
              {/* <FriendList
                friendData={inviteeData}
                RemoveFriend={RemoveFriend}
                SetRemoveFriendData={SetRemoveFriendData}
              /> */}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default MyFriends;
