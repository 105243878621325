import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import {
  Col,
  Row,
} from "reactstrap";
import { useLocalStorage } from "../../utils/useLocalStorage";
import PathArea from "../../components/PathArea";
import Search from "../../components/HomePage/SearchSEO";
import Book from "../../components/HomePage/Book";
import FlightList from "../../components/FlightList";
import RouteDescription from "./RouteDescription";
import "./../HomePage.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';

function HomePageNYtoSF() {
  const { route } = useParams();
  const location = useLocation();
  const [user] = useLocalStorage("auth", {});
  const [pathType, setPathType] = useState("search");
  const [unregistedFlightsData, setFlightData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState(0);
  const [sidebarTop, setSidebarTop] = useState(undefined);
  var [headerHeight, setHeaderHeight] = useState(125);
  const [from_airport, setFromAirport] = useState(null);
  const [to_airport, setToAirport] = useState(null);
  const [fromCity, setFromCity] = useState(null);
  const [toCity, setToCity] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [pageDescription, setPageDescription] = useState(null);
  const [searchDate, setSearchDate] = useState(null);

  const fetchRoutesfromParameter = (route) => {
    switch (route) {
      case "new-york-south-florida":
        setFromAirport("NYALL");
        console.log("SET FROM " + from_airport);
        setToAirport("SFALL");
        setFromCity("New York");
        setToCity("South Florida");
        setPageTitle("Shared Private Jet Flights From New York to South Florida");
        setPageDescription("Fly in ultimate comfort from New York to South Florida on a shared private jet flight. Luxury, convenience, and affordability combined. Book now!");
        break;
      case "new-york-aspen":
        setFromAirport("NYALL");
        console.log("SET FROM " + from_airport);
        setToAirport("ASE");
        setFromCity("New York");
        setToCity("Aspen");
        setPageTitle("Shared Private Jet Flights From New York to Aspen");
        setPageDescription("Fly in ultimate comfort from New York to Aspen on a shared private jet flight. Luxury, convenience, and affordability combined. Book now!");
        break;
      case "south-florida-new-york":
        setFromAirport("SFALL");
        setToAirport("NYALL");
        setFromCity("South Florida");
        setToCity("New York");
        setPageTitle("Shared Private Jet Flights From South Florida to New York");
        setPageDescription("Fly in comfort from South Florida to New York on our shared private jet flights. Experience convenience and luxury like never before. Book now!");
        break;
      case "teterboro-palm-beach":
        setFromAirport("TEB");
        setToAirport("PBI");
        setFromCity("Teterboro");
        setToCity("Palm Beach");
        setPageTitle("Shared Private Charter Jet Flights Teterboro to Palm Beach");
        setPageDescription("Book a private jet charter flight from Teterboro Airport (TEB) to Palm Beach. Luxury, convenience, and affordability combined with Katana.");
        break;
      case "palm-beach-teterboro":
        setFromAirport("PBI");
        setToAirport("TEB");
        setFromCity("Palm Beach");
        setToCity("Teterboro");
        setPageTitle("Shared Private Charter Jet Flights Palm Beach to Teterboro");
        setPageDescription("Book a shared private jet flight from Palm Beach to Teterboro Airport (TEB). Fly in style and convenience, sharing the cost with fellow travelers.");
        break;
      case "westchester-palm-beach":
        setFromAirport("HPN");
        setToAirport("PBI");
        setFromCity("Westchester");
        setToCity("Palm Beach");
        setPageTitle("Shared Private Jet Flights Westchester to West Palm Beach");
        setPageDescription("Fly in comfort from Westchester to West Palm Beach on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "palm-beach-westchester":
        setFromAirport("PBI");
        setToAirport("HPN");
        setFromCity("Palm Beach");
        setToCity("Westchester");
        setPageTitle("Shared Private Jet Flights West Palm Beach to Westchester");
        setPageDescription("Enjoy exclusive shared private jet flights from West Palm Beach to Westchester. Experience luxury travel and convenience, while sharing the cost.");
        break;
      case "los-angeles-aspen":
        setFromAirport("LOSALL");
        setToAirport("ASE");
        setFromCity("Los Angeles");
        setToCity("Aspen");
        setPageTitle("Shared Private Jet Flights From Los Angeles to Aspen");
        setPageDescription("Fly in comfort from Los Angeles to Aspen on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "aspen-los-angeles":
        setFromAirport("ASE");
        setToAirport("LOSALL");
        setFromCity("Aspen");
        setToCity("Los Angeles");
        setPageTitle("Shared Private Jet Flights From Aspen to Los Angeles");
        setPageDescription("Shared private jet flights from Aspen to Los Angeles. Fly in style and convenience, sharing the cost with fellow travelers. Book your flight now!");
        break;
      case "aspen-dallas":
        setFromAirport("ASE");
        setToAirport("DALALL");
        setFromCity("Aspen");
        setToCity("Dallas");
        setPageTitle("Shared Private Jet Flights From Aspen to Dallas | Fly Katana");
        setPageDescription("Enjoy exclusive shared private jet flights from Aspen to Dallas. Experience luxury travel and convenience, while sharing the cost.");
        break;
      case "dallas-aspen":
        setFromAirport("DALALL");
        setToAirport("ASE");
        setFromCity("Dallas");
        setToCity("Aspen");
        setPageTitle("Shared Private Jet Flights From Dallas to Aspen | Fly Katana");
        setPageDescription("Indulge in private jet flights from Dallas to Aspen and share the cost. Experience unmatched luxury and convenience as you soar to your mountain escape.");
        break;
      case "los-angeles-cabo-san-lucas":
        setFromAirport("LOSALL");
        setToAirport("CSLALL");
        setFromCity("Los Angeles");
        setToCity("Cabo San Lucas");
        setPageTitle("Shared Private Jet Flights Los Angeles to Cabo San Lucas");
        setPageDescription("Fly in comfort from Los Angeles to Cabo San Lucas on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "cabo-san-lucas-los-angeles":
        setFromAirport("CSLALL");
        setToAirport("LOSALL");
        setFromCity("Cabo San Lucas");
        setToCity("Los Angeles");
        setPageTitle("Shared Private Jet Flights Cabo San Lucas to Los Angeles");
        setPageDescription("Book a shared private jet flight from Cabo San Lucas to Los Angeles. Fly in style and convenience, sharing the cost with fellow travelers.");
        break;
      case "san-francisco-cabo-san-lucas":
        setFromAirport("SFO");
        setToAirport("CSLALL");
        setFromCity("San Francisco");
        setToCity("Cabo San Lucas");
        setPageTitle("Shared Private Jet Flights San Francisco to Cabo San Lucas");
        setPageDescription("Escape to paradise with shared private jet flights from San Francisco to Cabo San Lucas. Experience ultimate luxury and discover the beauty of Baja. Book now!");
        break;
      case "cabo-san-lucas-san-francisco":
        setFromAirport("CSLALL");
        setToAirport("SFO");
        setFromCity("Cabo San Lucas");
        setToCity("San Francisco");
        setPageTitle("Shared Private Jet Flights Cabo San Lucas to San Francisco");
        setPageDescription("Enjoy exclusive shared private jet flights from Cabo San Lucas to San Francisco. Experience luxury travel and convenience, while sharing the cost.");
        break;
      case "fort-lauderdale-westchester":
        setFromAirport("FLL");
        setToAirport("HPN");
        setFromCity("Fort Lauderdale");
        setToCity("Westchester");
        setPageTitle("Shared Private Jet Flights Fort Lauderdale to Westchester");
        setPageDescription("Fly in comfort from Fort Lauderdale to Westchester on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "westchester-fort-lauderdale":
        setFromAirport("HPN");
        setToAirport("FLL");
        setFromCity("Westchester");
        setToCity("Fort Lauderdale");
        setPageTitle("Shared Private Jet Flights Westchester to Fort Lauderdale");
        setPageDescription("Book a shared private jet flight from Westchester to Fort Lauderdale. Fly in style and convenience, sharing the cost with fellow travelers.");
        break;
      default:
        const [from, to] = route.split('-');
        console.log(from, to, "check");
        if (from) {
          setFromAirport(from);
          if (to != "null") setToAirport(to);
          setPageTitle("Empty Legs");
          const queryParams = new URLSearchParams(location?.search);
          const date = queryParams.get("date");
          if (date) setSearchDate(date);
          // Update the URL without reloading the page
          window?.history?.replaceState({}, document.title, '/');
        } else {
          setFromCity("New York");
          setToCity("South Florida");
          setPageTitle("Shared Private Jet Flights From South Florida to New York");
          setPageDescription("Fly in comfort from South Florida to New York on our shared private jet flights. Experience convenience and luxury like never before. Book now!");
        }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const chatEl = document.querySelector('.sticky-component').getBoundingClientRect();
    setSidebarTop(chatEl.top);

  }, [from_airport]);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };

  }, [sidebarTop]);

  const isSticky = (e) => {
    const chatEl = document.querySelector('.sticky-component');
    const scrollTop = window.scrollY;
    setHeaderHeight(document.getElementById('top-header').offsetHeight);
    //      console.log("HeadHeight: "+document.getElementById('top-header').offsetHeight);
    headerHeight = document.getElementById('top-header').offsetHeight;
    //  console.log("scrollTop: "+scrollTop);
    //  console.log("sidebarTop: "+sidebarTop);
    //  console.log("Header height: "+headerHeight);

    if (scrollTop >= sidebarTop - headerHeight) {
      document.documentElement.style.setProperty('--sticky-top', headerHeight + 'px');
      chatEl.classList.add('is-sticky');
    } else {
      chatEl.classList.remove('is-sticky');
    }
  };

  useEffect(() => {
    //Fetch from and to city from route names
    console.log("Route: " + route);
    fetchRoutesfromParameter(route);
    console.log(`From: ${from_airport}, To: ${to_airport}`);
  }
    , [from_airport, to_airport])

  useEffect(() => {
    // ADD H1 tag to the top of body
    // if(fromCity && toCity){
    //   const body = document.getElementsByTagName("body")[0];
    //   const h1 = document.createElement("h1");
    //   h1.innerText = "Shared private Flights from "+fromCity+" to "+toCity;
    //   h1.style.display = "none";
    //   body.insertBefore(h1, body.firstChild);
    // }
  }, [from_airport, to_airport])

  useEffect(() => {
    setFilteredData(unregistedFlightsData);
    handleChange(null, 0);
    //setValue(1);
    //setFilteredData(unregistedFlightsData.filter((x) => x.is_posted == true && x.is_charter == false))
  }
    , [unregistedFlightsData])

  const handleChange = (event, newValue) => {
    setFilteredData(unregistedFlightsData);
    setValue(newValue);
    console.log("New Value: " + newValue);
    //console.log("Flight list: "+unregistedFlightsData);
    if (newValue == 0) {
      setFilteredData(unregistedFlightsData)
    }
    if (newValue == 1) {
      setFilteredData(unregistedFlightsData.filter((x) => x.is_posted == true && x.is_charter == false))
    }
    if (newValue == 2) {
      setFilteredData(unregistedFlightsData.filter((x) => x.is_charter == true))
    }
    if (newValue == 3) {
      setFilteredData(unregistedFlightsData.filter((x) => x.is_posted == false))
    }
  };


  return (
    <>
      {fromCity && toCity && pageTitle && pageDescription && (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link
            rel="canonical"
            href={`https://www.flykatana.com/routes/${route}`}
          ></link>
        </Helmet>
      )}
      {pathType === "book" && (
        <>
          <Row>
            <Col
              lg={{ size: 12 }}
              md={{ size: 12 }}
              sm="12"
              className="text-center"
            >
              <Grid className="imgBackground">
                <div className="fullWidthHomeSearch m-3">
                  <Row>
                    <Col
                      lg={{ size: "6", offset: "3" }}
                      md={{ size: "6", offset: "3" }}
                      sm="12"
                    >
                      <Card className="p-3">
                        {typeof user.userid !== "undefined" && (
                          <Row className="mt-2">
                            <Col lg="12" md="12" sm="12">
                              <PathArea
                                pathType={pathType}
                                setPathType={setPathType}
                                user={user}
                              />
                            </Col>
                          </Row>
                        )}
                        <Book />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Grid>
            </Col>
          </Row>
        </>
      )}
      {pathType === "search" && (
        <>
          <Grid className="imgBackground">
            <div className="fullWidthHomeSearch m-3 mb-6">
              {(fromCity && toCity) ? (
                <h1
                  className="text-center"
                  style={{ marginTop: "0.5rem", color: "black" }}
                >
                  Shared private flights from {fromCity} to {toCity}
                </h1>
              ) :
                (<br />)}
              <Row>
                <Col
                  lg={{ size: "8", offset: "2" }}
                  md={{ size: "8", offset: "2" }}
                  sm="12"
                >
                  <Card className="p-3 overflow-visible">
                    {/* {typeof user.userid !== "undefined" && ( */}
                    <Row className="mt-2">
                      <Col lg="12" md="12" sm="12">
                        <PathArea
                          pathType={pathType}
                          user={user}
                          setPathType={setPathType}
                        />
                      </Col>
                    </Row>
                    {/* )} */}
                    {from_airport && (
                      <Search
                        unregistedFlightsData={unregistedFlightsData}
                        setFlightData={setFlightData}
                        fromAirport={from_airport}
                        toAirport={to_airport}
                        searchDate={searchDate}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
          </Grid>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-3"></Col>
            <Col lg="12" md="12" sm="12" className="mb-3">
              <div className="sticky-component">
                <Box sx={{ bgcolor: "background.paper" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="#FFE600"
                    aria-label="scrollable auto tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#FFE600",
                        height: 3,
                        textColor: "#FFE600",
                      },
                    }}
                  >
                    <Tab label="All" />
                    <Tab label="Seats" />
                    <Tab label="Charters" />
                    <Tab label="Interests" />
                  </Tabs>
                </Box>
              </div>
              <FlightList
                flightData={filteredData}
                setFlightData={setFilteredData}
                page="Home"
              />
              {from_airport && to_airport && (
                <RouteDescription fromCity={fromCity} toCity={toCity} />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default HomePageNYtoSF
