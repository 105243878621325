import React, { useEffect, useState } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import './Testimonials.css'
const testimonials = [
    {
        key: '1',
        label: 'Robust Inventory',
        content: `"Katana is the first place I look when booking flight reservation.
                They have the most comprehensive inventory of seats and empty
                leg charters." `,
        name: '- William L.'
    },
    {
        key: '2',
        label: 'Affordable Luxury Travel',
        content: `"Discovering Katana opened up the world of private flying to me.
                The platform offers affordable deals on seats and even some
                empty-leg flights. It is easy to use and has made luxury travel
                unexpectedly accessible."`,
        name: '- Stephanie Z.'
    },
    {
        key: '3',
        label: 'Sustainable and Smart Travel',
        content: `"Booking seats on flights that would otherwise fly less than full or
                even empty via Katana has allowed me to travel luxuriously while
                being smart and sustainable. It's a win-win, offering both
                environmental benefits and the unparalleled experience of private
                flying."`,
        name: ' - Wendy H.'
    },
    {
        key: '4',
        label: 'Networking in the Skies',
        content: `"Katana is more than a flight booking service; it's a community
                where I've met fascinating people, leading to new friendships and
                business opportunities. Buying and selling excess seats has
                introduced a unique networking angle to travel."`,
        name: ' - Jason P.'
    },
    {
        key: '5',
        label: 'Maximizing Value and Connections',
        content: `"Listing excess seats on Katana has been easy and beneficial,
                reducing my travel costs. I even met a couple who live in my
                resort community who are now dear friends."`,
        name: ' - Ronaldo S.'
    },
    {
        key: '6',
        label: 'Enhancing Resort Accessibility and Community',
        content: `"As a resort manager, Katana has revolutionized the way our
                residents travel to our property, making it seamless and sociable.
                Its service not only simplifies booking private flights but also
                fosters a vibrant community by connecting residents with shared
                destinations."`,
        name: '- Jamie P.'
    },
];

// Chunk array function to split testimonials into groups of 3
function chunkArray(array, size) {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
        array.slice(index * size, index * size + size)
    );
}

function Testimonials() {
    const testimonialsChunks = chunkArray(testimonials, 3);
    return (
        <>
            <h1 className='text-center'>What Our User's Say</h1>
            <div className='shoCarouselForwmax'>
                <Carousel prevIcon={<BsChevronLeft style={{ color: 'black', fontSize: "40px" }} />} nextIcon={<BsChevronRight style={{ color: 'black', fontSize: "40px" }} />}>
                    {testimonialsChunks.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <div className="d-flex justify-content-center bg-white" style={{ gap: '3%' }}>
                                {chunk.map(testimonial => (
                                    <Card className='cardfortestimonial' key={testimonial.key} style={{ width: '30%' }}>
                                        <Card.Body className='cardbodyfortestimonial'>
                                            <Card.Title style={{ fontSize: '1rem' }}>{testimonial.label}</Card.Title>
                                            <Card.Text>{testimonial.content}</Card.Text>
                                            <Card.Text style={{ fontWeight: 'bold' }}>{testimonial.name}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className='shoCarouselFormin'>
                <Carousel prevIcon={<BsChevronLeft style={{ color: 'black', fontSize: "40px" }} />} nextIcon={<BsChevronRight style={{ color: 'black', fontSize: "40px" }} />}>
                    {testimonials.map(testimonial => (
                        <Carousel.Item key={testimonial.key}>
                            <div className="d-flex justify-content-center  bg-white" style={{ gap: '3%' }}>
                                <Card className='cardfortestimonial' style={{ width: '100%' }}>
                                    <Card.Body className='cardbodyfortestimonial'>
                                        <Card.Title style={{ fontSize: '1rem' }}>{testimonial.label}</Card.Title>
                                        <Card.Text>{testimonial.content}</Card.Text>
                                        <Card.Text style={{ fontWeight: 'bold' }}>{testimonial.name}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
}

export default Testimonials;