import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { Snackbar } from "@material-ui/core";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Table,
  Button,
  CardBody,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useFriendsData } from "../../data/FrindsData";
import FilterFriend from "../FilterFriend";

function ContactListModal({
  modal,
  setModal,
  contactList,
  SetMobile,
  SetName,
  search,
  SetSearch,
  page
}) {
  const toggle = () => setModal(!modal);
  const [friend, setFriend] = useState("No");
  const [users, setUsers] = useState([]);
  const [isAdded, SetAdded] = useState(false);
  const [user, User] = useLocalStorage("auth", {});
  const { AddAllKatanaMembers } = useFriendsData();

  useEffect(() => {
    if (friend == "Yes") {
      FindKatanaContacts();
    } else {
      setUsers(contactList);
    }
  }, [friend, contactList]);

  function SelectContact(contact) {
    SetMobile(contact.phone);
    SetName(contact.name);
    toggle();
  }

  async function FindKatanaContacts() {
    const katanaUsers = contactList.filter((x) => x.is_user === true);
    setUsers(katanaUsers);
  }

  async function AddFriend() {
    let usersMobile = [];
    users.map((user) => {
      usersMobile.push(user.phone);
    });
    let body = {};
    body.mobile = user.mobile;
    body.inviteename = user.name;
    body.invmobile = usersMobile;
    const data = await AddAllKatanaMembers(body);
    if (data.status) {
      SetAdded(true);
      toggle();
    }
  }

  return (
    <>
      {isAdded && (
        <Snackbar
          open={isAdded}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetAdded(!isAdded)}
        >
          <Alert className="m-2 alertYellowInverse">
            Friend request sent successfully to all.
          </Alert>
        </Snackbar>
      )}
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={"static"}
        keyboard={false}
        size="lg"
      >
        <ModalHeader
          toggle={toggle}
          className="p-1 border-0"
          style={{ backgroundColor: "#fff", boxShadow: "none !important" }}
        ></ModalHeader>
        <ModalBody className="p-0">
          <Card className="shadow border-0">
            <CardBody>
              <Input
                type="text"
                placeholder="Search contact"
                className="form-control mb-3"
                value={search}
                onChange={(e) => SetSearch(e.target.value)}
              ></Input>
              {page == "myFriend" && (
                <FilterFriend friend={friend} setFriend={setFriend} />
              )}

              {/* <Button className="btnYellow btn-block" onClick={(e)=>{FindKatanaContacts()}}>Find Katana Members</Button> */}

              <div style={{ maxHeight: "400px", overflow: "scroll" }}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="bg-dark text-yellow">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users.length > 0 &&
                      users.map((contact) => {
                        return (
                          <tr key={contact.id}>
                            <th scope="row">{contact.name}</th>
                            <td scope="row">{contact.phone}</td>
                            <td className="text-right">
                              <Button
                                className="btn btn-sm btnYellow"
                                onClick={(e) => SelectContact(contact)}
                              >
                                Select
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
              {friend == "Yes" && (
                <Row className="row text-center">
                  <Col lg="12" md="12" sm="12">
                    <Button
                      className="btnYellow btn-block"
                      onClick={(e) => {
                        AddFriend();
                      }}
                    >
                      Add All
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ContactListModal;
