import React, { useEffect } from "react";
import { useGoogleData } from "../utils/GooglePeopleApi";
import { useLocalStorage } from "../utils/useLocalStorage";

function AuthorizeGoogle() {
  const { GetAccessTokenByAuthCode } = useGoogleData();

  const [user, SetUser] = useLocalStorage("auth", {});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    if (code) {
      setTimeout(function () {
        GetAccessTokenByCode(code, state);
      }, 300);
    }
  }, []);

  async function GetAccessTokenByCode(code, state) {
    const response = await GetAccessTokenByAuthCode(user.userid, code);
    if (response) {
      let userDetails = user;
      userDetails.is_google_linked = true;
      SetUser(userDetails);
      if (state !== null || state != undefined || state != ""){
        window.location.href = state;
      }
      else
      {window.location.href = "/MyFriends"};
    }
  }

  return (
    <div className="text-center">
      <h1>Google Authorized Successfully.</h1>
    </div>
  );
}

export default AuthorizeGoogle;
