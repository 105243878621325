import React from 'react'
import Aboutimage from '../assets/images/AboutPage.png'
function About() {
    return (
        <div style={{ paddingLeft: '6%', paddingRight: '6%' }} className='py-3'>
            <div className='text-center'>
                <img className='w-100' src={Aboutimage} style={{ objectFit: 'cover', height: '400px', paddingTop: '15px' }} alt="Image here" />
            </div>
            <div style={{ paddingTop: '10px' }}>
                <p>Hello Katana Family!</p>
                <p>I'm Bryan Verona, thrilled to be the founder of Katana and even more excited to welcome you
                    aboard our journey. Born amidst the challenges of the Pandemic, Katana became my beacon of
                    innovation as we all sought safer and more convenient ways to fly. Like many of you, I value
                    smart choices in travel, whether it's snagging that extra legroom seat or indulging in the luxury
                    of private aviation, always with an eye on the balance between cost and convenience.</p>
                <p>Here's the thing: I love the idea of flying private, but the sight of empty seats on a flight just
                    doesn't sit right with me. And it's not just me—so many times, friends or acquaintances were
                    headed the same way but just didn't have the means to connect. That's exactly why Katana was
                    born.</p>
                <p>Let me share a quick story that paints the picture perfectly.</p>
                <p>Planning a trip to Aspen for a long weekend, I juggled around our departure to suit school
                    schedules and a special dinner in Boulder at Stella's Cucina (a must-visit, by the way, thanks to
                    my wife's cousin). Everything was set: a flight from Newark, a delightful dinner, and then off to Aspen. But then came the delays—first two hours, then another two. Our plans for dinner and a
                    smooth ride to Aspen were crumbling.</p>
                <p>That's when Katana came to the rescue. I quickly found an empty-leg charter, listed our extra
                    seats on Katana, and headed to Teterboro instead. Imagine if more folks were on Katana—
                    those stranded at the airport could have joined us, sharing the cost and the experience of flying
                    private to Aspen.</p>
                <p>Katana isn't just a platform; it's a community-driven meta-search engine for private aviation. It's
                    where you can find and fill those extra seats, explore empty leg charters, or choose from
                    commercial seat charter options like XO Jet and Aero. Our vision? To make private flying an
                    option for more people by improving capacity utilization and making it as affordable as a
                    business class ticket, especially when you share.</p>
                <p>I'm here to make sure you love every moment of your Katana experience. Got ideas, questions,
                    or feedback? Drop me a line anytime at BV@FlyKatana.com. Together, let's redefine the way
                    we fly.</p>
                <p>Fly high and see you soon,</p>
                <p>Bryan Verona</p>
            </div>
        </div>
    )
}

export default About