import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authAndUserData } from "../../data/AuthAndUserData";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useValidateData } from "../../data/Validation";
import LoginImage from "../../assets/images/login.png";
import HomeAway from "../HomeAway";
import { Input, FormControl, InputLabel, FormGroup } from "@material-ui/core";
import { Button, Alert } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function SignUp({ setMode, setSignUpSuccess }) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [user, setUser] = useLocalStorage("auth", {});
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [home, setHome] = useState(null);
  const [away, setAway] = useState(null);
  const [loginImage, SetLoginImage] = useState(LoginImage);
  const [fileData, SetFileData] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();

  const { PostCreateNewUser } = authAndUserData();
  const { CheckEmailRegistered, CheckUserByMobile } = useValidateData();
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  async function OnSignUp(event) {
    if (!isChecked) {
      setErrorMessage("Please accept Terms and Conditions");
      setIsError(true);
    }
    if (await ValidateData()) {
      const response = await PostCreateNewUser(
        email,
        name,
        parseInt(mobile, 10),
        home,
        away,
        password
      );
      if (response) {
        // setMode("Login");
        // setSignUpSuccess(true);
        // setErrorMessage("");
        // setIsError(false);

        setUser(response);
        setIsError(false);
        setErrorMessage("");

        // Send data to GTM for Google Analytics.
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "signup_complete",
        });

        //Redirect to home page
        if (localStorage?.getItem('from') && localStorage?.getItem("to") && localStorage?.getItem("Inquire")) {
          let date = localStorage?.getItem('date')
          if (date) {
            window.location = `/routes/${localStorage?.getItem('from')}-${localStorage?.getItem("to")}?date=${date}`;
            localStorage?.removeItem('date')
          } else {
            window.location = `/routes/${localStorage?.getItem('from')}-${localStorage?.getItem("to")}`;

          }
          localStorage?.removeItem("to")
          localStorage?.removeItem("from")
          localStorage?.removeItem("Inquire")
        } else {
          localStorage?.removeItem("to")
          localStorage?.removeItem("from")
          window.location = "/plans";
        }
      } else {
        setErrorMessage("Something went wrong");
        setIsError(true);
      }
    }
  }

  async function ValidateData() {
    if (email === "" || email === undefined || email === null) {
      setErrorMessage("Please input your email");
      setIsError(true);
      return false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      setErrorMessage("Please enter a valid email.");
      setIsError(true);
    } else if (await CheckEmailRegistered(email)) {
      setErrorMessage("Email already registered.");
      setIsError(true);
      return false;
    } else if (name === "" || name === undefined || name === null) {
      setErrorMessage("Please input your name");
      setIsError(true);
      return false;
    } else if (mobile === "" || mobile === undefined || mobile === null) {
      setErrorMessage("Please input your mobile");
      setIsError(true);
      return false;
    } else if (await CheckUserByMobile(mobile)) {
      setErrorMessage("Mobile already registered.");
      setIsError(true);
      return false;
    } else if (password === "" || password === undefined || password === null) {
      setErrorMessage("Please input your password");
      setIsError(true);
      return false;
    } else if (!isChecked) {
      setErrorMessage("Please accept Terms and Conditions");
      setIsError(true);
      return false;
    }
    // else if (home === "" || home === undefined || home === null) {
    //   setErrorMessage("Please input your home");
    //   setIsError(true);
    //   return false;
    // }
    // else if (away === "" || away === undefined || away === null) {
    //   setErrorMessage("Please input your away");
    //   setIsError(true);
    //   return false;
    // }
    else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }

  function SelectProfilePicture(e) {
    document.getElementById("selectImage").click();
  }

  function UploadImage(e) {
    SetFileData(e.target.files[0]);
    SetLoginImage(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div>
      <FormGroup>
        <div className="text-center m-3">
          <b
            className="text-dark"
            style={{ fontSize: "16px", fontWeight: "bolder" }}
          >
            Join now and get your first 3 months
          </b>
          <br />
          <b
            className="text-dark"
            style={{ fontSize: "16px", fontWeight: "bolder" }}
          >
            of Membership for FREE!
          </b>
        </div>
        <FormControl fullWidth>
          <div className="text-center">
            <img
              src={loginImage}
              style={{ height: "100px", width: "100px" }}
              onClick={SelectProfilePicture}
            />
            <input
              type="file"
              onChange={UploadImage}
              multiple={false}
              accept="image/*"
              id="selectImage"
              hidden
            ></input>
          </div>
          <br />
        </FormControl>
        {isError && <Alert color="danger">{errorMessage}</Alert>}
        <FormControl fullWidth className="mb-2">
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth className="mb-2">
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth className="mb-2">
          {/* <InputLabel htmlFor="mobile">(+1) Enter 10 digit mobile</InputLabel> */}
          {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
          <p style={{ color: "black", marginBottom: "0" }}>Mobile</p>
          <PhoneInput
            country={"us"}
            value={mobile}
            onChange={(phone) => setMobile(phone)}
          />
          {/* </div> */}
          {/* <Input
            id="mobile"
            type="number"
            value={mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10) setMobile(e.target.value);
            }}
          /> */}
        </FormControl>
        <FormControl fullWidth className="mb-2">
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </FormControl>
        {/* ALI CHANGE 24-10-23 */}
        {/* <FormControl fullWidth className="mb-2 row">
          <HomeAway
            type="Home"
            code={home}
            setCode={setHome}
            Input={Input}
            InputLabel={InputLabel}
            Page="SignUp"
          />
        </FormControl>
        <FormControl fullWidth className="mb-2 row">
          <HomeAway
            type="Away"
            code={away}
            setCode={setAway}
            Input={Input}
            InputLabel={InputLabel}
            Page="SignUp"
          />
        </FormControl> */}
      </FormGroup>

      <div className="text-center mb-3">
        <Button
          name="login"
          onClick={OnSignUp}
          className="btn btn-block btnYellow"
        >
          Subscribe
        </Button>
      </div>
      <div>
        <label style={{ fontSize: 12 }}>
          <input
            type="checkbox"
            id="TnC"
            name="TnC"
            value="TnC"
            style={{ zoom: 1, marginRight: "5px" }}
            checked={isChecked}
            onChange={handleOnChange}
          />
          Katana operates a short code campaign (SMS) that sends Opted-in
          Members messages regarding Available Seats and Flight interest. By
          Opting in and submitting your mobile phone number, you will be
          subscribed to Katana's messaging service, the core functionality of
          Katana's service. Please review our{" "}
          <a href="./legal">(SMS) Terms and Conditions and Privacy Policy</a>.
          Message and data rates may apply.
        </label>
        <br />
      </div>
      <div className="text-center">
        <i>Have an account?</i>&nbsp;&nbsp;&nbsp;
        <b>
          <Link onClick={(e) => setMode("Login")} style={{ color: "black" }}>
            Log In
          </Link>
        </b>
      </div>
    </div>
  );
}

export default SignUp;
