import React from "react";
import "./Footer.css";
import facebook from "../assets/images/social/facebook1.png";
import instagram from "../assets/images/social/instagram.png";
import linkedin from "../assets/images/social/linkedin.png";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-divItem text-center mb-1">
        <h3>
          <b>Shared Flights</b>
        </h3>
        <a
          href={process.env.REACT_APP_DOMAIN + "/routes/westchester-palm-beach"}
        >
          <h4>Westchester - West Palm Beach</h4>
        </a>
        <a href={process.env.REACT_APP_DOMAIN + "/routes/dallas-aspen"}>
          <h4>Dallas - Aspen</h4>
        </a>
        <a
          href={
            process.env.REACT_APP_DOMAIN + "/routes/los-angeles-cabo-san-lucas"
          }
        >
          <h4>Los Angeles - Cabo San Lucas</h4>
        </a>
        <a
          href={
            process.env.REACT_APP_DOMAIN + "/routes/westchester-fort-lauderdale"
          }
        >
          <h4>Westchester - Fort Lauderdale</h4>
        </a>
        <a href={process.env.REACT_APP_DOMAIN + "/routes/los-angeles-aspen"}>
          <h4>Los Angeles - Aspen</h4>
        </a>
        <a
          href={process.env.REACT_APP_DOMAIN + "/routes/new-york-south-florida"}
        >
          <h4>New York - South Florida</h4>
        </a>
        <a href={process.env.REACT_APP_DOMAIN + "/routes/new-york-aspen"}>
          <h4>New York - Aspen</h4>
        </a>
      </div>
      <div
        className="footer-divItem text-center mb-1"
        style={{ alignSelf: "center" }}
      >
        <div className="social-logos">
          <a target="_blank" href="https://www.facebook.com/FlyKatanaNow/">
            <img src={facebook} alt="Facebook" />
          </a>
          <a target="_blank" href="https://www.instagram.com/fly.katana/">
            <img src={instagram} alt="Instagram" />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/fly-katana/"
          >
            <img src={linkedin} alt="LinkedIn" />
          </a>
        </div>
        <a href="mailto:info@FlyKatana.com">
          <h4>info@FlyKatana.com</h4>
        </a>
        <a href="tel:(929) 318-9292">
          <h4>(929) 318-9292</h4>
        </a>
        <h4>&#169;2023 by Katana</h4>
      </div>
    </div>
  );
}

export default Footer;
