import React from 'react'
import { Card, CardBody, Row, Col } from "reactstrap";
import "./common.css";

function Legal() {
    return (
      <>
        <Card style={{ margin: 0, padding: 0, overflowY: "scroll" }}>
          <CardBody>
            <div className="container">
              <Row className="mb-4">
                <Col lg="12" md="12" sm="12">
                  <h1>LEGAL</h1>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg="12" md="12" sm="12">
                  <p class="text-dark font-weight-bold text-justify">
                    Katana is not a direct or foreign air carrier (“Air
                    Carrier”) , does not possess an air carrier certificate or
                    economic authority to sell air transportation, and is not
                    selling air transportation. All transportation is provided
                    by air carriers authorized by the Federal Aviation
                    Administration (“FAA”) and the U.S. Department of
                    Transportation to perform flights under the applicable FAA
                    regulations (i.e., 14 C.F.R. Parts 121 or 135). The only
                    persons selling air transportation are the air carriers or
                    persons acting as authorized agents of the direct air
                    carriers. The air carrier providing the air transportation
                    will possess operational control of the flight. Passengers
                    will be provided with the following prior to the flight:
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify mb-1">
                    (1) The corporate name of the direct air carrier or direct
                    foreign air carrier in operational control of the aircraft
                    on which the air transportation is to be performed and any
                    other names in which that direct carrier holds itself out to
                    the public;
                  </p>
                  <p class="text-dark font-weight-bold text-justify mb-1">
                    (2) The capacity in which the agent, if applicable, is
                    acting in contracting for the air transportation, i.e., as
                    an indirect air carrier, indirect foreign air carrier, as an
                    agent of the charterer, or as an agent of the direct air
                    carrier or direct foreign air carrier that will be in
                    operational control of the flight; and
                  </p>
                  <p class="text-dark font-weight-bold text-justify mb-1">
                    (3) The existence or absence of liability insurance held by
                    the agent covering the charterer and passengers and property
                    on the charter flight, and the monetary limits of any such
                    insurance.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    All passengers acknowledge and agree that Katana, its
                    affiliates, officers, directors and agents, are not
                    responsible for the acts or omissions of the air carrier or
                    its agent, or the performance or nonperformance of any
                    flight or any other matters outside Katana’s control.
                  </p>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" sm="12" className="mb-2">
                  <h2>TERMS & CONDITIONS</h2>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg="12" md="12" sm="12">
                  <p class="text-dark font-weight-bold text-justify">
                    Agreement between User and{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>
                    <br />
                    Welcome to{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>
                    . The{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    website (the "Site") is comprised of various web pages
                    operated by Lartnow LLC dba "Katana" ("Katana") or
                    "FlyKatana" ("FlyKatana").{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    is offered to you conditioned on your acceptance without
                    modification of the terms, conditions, and notices contained
                    herein (the "Terms"). Your use of{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    constitutes your agreement to all such Terms. Please read
                    these terms carefully, and keep a copy of them for your
                    reference.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    is an E-Commerce Site.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Katana seeks to simplify private plane travel by creating a
                    seamless system for finding flights and indicating flight
                    interest to people you don't mind sharing a private plane
                    with.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Privacy
                    <br /> Your use of{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    is subject to Katana's Privacy Policy. Please review our
                    Privacy Policy, which also governs the Site and informs
                    users of our data collection practices.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Electronic Communications
                    <br /> Visiting{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    or sending emails to Katana constitutes electronic
                    communications. You consent to receive electronic
                    communications and you agree that all agreements, notices,
                    disclosures, and other communications that we provide to you
                    electronically, via email, and on the Site, satisfy any
                    legal requirement that such communications be in writing.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Your Account
                    <br /> If you use this site, you are responsible for
                    maintaining the confidentiality of your account and for
                    restricting access to your computer, and you agree to accept
                    responsibility for all activities that occur under your
                    account. You may not assign or otherwise transfer your
                    account to any other person or entity. You acknowledge that
                    Katana is not responsible for third-party access to your
                    account that results from theft or misappropriation of your
                    account. Katana and its associates reserve the right to
                    refuse or cancel service, terminate accounts, or remove or
                    edit content at our sole discretion.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Children Under Thirteen
                    <br /> Katana does not knowingly collect, either online or
                    offline, personal information from persons under the age of
                    thirteen. If you are under 18, you may use{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>
                    only with permission of a parent or guardian.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Links to Third Party Sites/Third Party Services
                    <br />{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    may contain links to other websites ("Linked Sites"). The
                    Linked Sites are not under the control of Katana and Katana
                    is not responsible for the contents of any Linked Site,
                    including without limitation any link contained in a Linked
                    Site, or any changes or updates to a Linked Site. Katana is
                    providing these links to you only as a convenience, and the
                    inclusion of any link does not imply endorsement by Katana
                    of the site or any association with its operators.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Certain services made available via{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    are delivered by third-party sites and organizations. By
                    using any product, service, or functionality originating
                    from the
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    domain, you hereby acknowledge and consent that Katana may
                    share such information and data with any third party with
                    whom Katana has a contractual relationship to provide the
                    requested product, service, or functionality on behalf of{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    users and customers.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    No Unlawful or Prohibited Use/Intellectual Property Certain
                    <br />
                    You are granted a non-exclusive, non-transferable, revocable
                    license to access and use{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    strictly in accordance with these terms of use. As a
                    condition of your use of the Site, you warrant to Katana
                    that you will not use the Site for any purpose that is
                    unlawful or prohibited by these Terms. You may not use the
                    Site in any manner which could damage, disable, overburden,
                    or impair the Site or interfere with any other party's use
                    and enjoyment of the Site. You may not obtain or attempt to
                    obtain any materials or information through any means not
                    intentionally made available or provided for through the
                    Site.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    All content included as part of the Service, such as text,
                    graphics, logos, images, as well as the compilation thereof,
                    and any software used on the Site, is the property of Katana
                    or its suppliers and protected by copyright and other laws
                    that protect intellectual property and proprietary rights.
                    You agree to observe and abide by all copyright and other
                    proprietary notices, legends, or other restrictions
                    contained in any such content and will not make any changes
                    thereto.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    You will not modify, publish, transmit, reverse engineer,
                    participate in the transfer or sale, create derivative
                    works, or in any way exploit any of the content, in whole or
                    in part, found on the Site. Katana content is not for
                    resale. Your use of the Site does not entitle you to make
                    any unauthorized use of any protected content, and in
                    particular, you will not delete or alter any proprietary
                    rights or attribution notices in any content. You will use
                    protected content solely for your personal use and will make
                    no other use of the content without the express written
                    permission of Katana and the copyright owner. You agree that
                    you do not acquire any ownership rights in any protected
                    content. We do not grant you any licenses, express or
                    implied, to the intellectual property of Katana or our
                    licensors except as expressly authorized by these Terms.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Use of Communication Services
                    <br /> The Site may contain bulletin board services, chat
                    areas, news groups, forums, communities, personal web pages,
                    calendars, and/or other message or communication facilities
                    designed to enable you to communicate with the public at
                    large or with a group (collectively, "Communication
                    Services"). You agree to use the Communication Services only
                    to post, send and receive messages and material that are
                    proper and related to the particular Communication Service.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    By way of example, and not as a limitation, you agree that
                    when using a Communication Service, you will not: defame,
                    abuse, harass, stalk, threaten or otherwise violate the
                    legal rights (such as rights of privacy and publicity) of
                    others; publish, post, upload, distribute or disseminate any
                    inappropriate, profane, defamatory, infringing, obscene,
                    indecent or unlawful topic, name, material or information;
                    upload files that contain software or other material
                    protected by intellectual property laws (or by rights of
                    privacy of publicity) unless you own or control the rights
                    thereto or have received all necessary consents; upload
                    files that contain viruses, corrupted files, or any other
                    similar software or programs that may damage the operation
                    of another's computer; advertise or offer to sell or buy any
                    goods or services for any business purpose, unless such
                    Communication Service specifically allows such messages;
                    conduct or forward surveys, contests, pyramid schemes or
                    chain letters; download any file posted by another user of a
                    Communication Service that you know, or reasonably should
                    know, cannot be legally distributed in such manner; falsify
                    or delete any author attributions, legal or other proper
                    notices or proprietary designations or labels of the origin
                    or source of software or other material contained in a file
                    that is uploaded; restrict or inhibit any other user from
                    using and enjoying the Communication Services; violate any
                    code of conduct or other guidelines which may be applicable
                    for any particular Communication Service; harvest or
                    otherwise collect information about others, including e-mail
                    addresses, without their consent; violate any applicable
                    laws or regulations.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Katana has no obligation to monitor the Communication
                    Services. However, Katana reserves the right to review
                    materials posted to a Communication Service and to remove
                    any materials in its sole discretion. Katana reserves the
                    right to terminate your access to any or all of the
                    Communication Services at any time without notice for any
                    reason whatsoever.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Katana reserves the right at all times to disclose any
                    information as necessary to satisfy any applicable law,
                    regulation, legal process, or governmental request, or to
                    edit, refuse to post, or to remove any information or
                    materials, in whole or in part, in Katana's sole discretion.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Always use caution when giving out any personally
                    identifying information about yourself or your children in
                    any Communication Service. Katana does not control or
                    endorse the content, messages, or information found in any
                    Communication Service and, therefore, Katana specifically
                    disclaims any liability with regard to the Communication
                    Services and any actions resulting from your participation
                    in any Communication Service. Managers and hosts are not
                    authorized Katana spokespersons, and their views do not
                    necessarily reflect those of Katana.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Materials uploaded to a Communication Service may be subject
                    to posted limitations on usage, reproduction and/or
                    dissemination. You are responsible for adhering to such
                    limitations if you upload the materials.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Materials Provided to{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    or Posted on Any Katana Web Page
                    <br /> Katana does not claim ownership of the materials you
                    provide to{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    (including feedback and suggestions) or post, upload, input
                    or submit to any Katana Site or our associated services
                    (collectively "Submissions"). However, by posting,
                    uploading, inputting, providing or submitting your
                    Submission you are granting Katana, our affiliated companies
                    and necessary sublicensees permission to use your Submission
                    in connection with the operation of their Internet
                    businesses including, without limitation, the rights to:
                    copy, distribute, transmit, publicly display, publicly
                    perform, reproduce, edit, translate and reformat your
                    Submission; and to publish your name in connection with your
                    Submission.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    No compensation will be paid with respect to the use of your
                    Submission, as provided herein. Katana is under no
                    obligation to post or use any Submission you may provide and
                    may remove any Submission at any time in Katana's sole
                    discretion.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    By posting, uploading, inputting, providing or submitting
                    your Submission you warrant and represent that you own or
                    otherwise control all of the rights to your Submission as
                    described in this section including, without limitation, all
                    the rights necessary for you to provide, post, upload, input
                    or submit the Submissions.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    International Users
                    <br /> The Service is controlled, operated, and administered
                    by Katana from our offices within the USA. If you access the
                    Service from a location outside the USA, you are responsible
                    for compliance with all local laws. You agree that you will
                    not use the Katana Content accessed through
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    in any country or in any manner prohibited by any applicable
                    laws, restrictions, or regulations.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Indemnification
                    <br /> You agree to indemnify, defend and hold harmless
                    Katana, its officers, directors, employees, agents, and
                    third parties, for any losses, costs, liabilities, and
                    expenses (including reasonable attorney's fees) relating to
                    or arising out of your use of or inability to use the Site
                    or services, any user postings made by you, your violation
                    of any terms of this Agreement or your violation of any
                    rights of a third party, or your violation of any applicable
                    laws, rules or regulations. Katana reserves the right, at
                    its own cost, to assume the exclusive defense and control of
                    any matter otherwise subject to indemnification by you, in
                    which event you will fully cooperate with Katana in
                    asserting any available defenses.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Arbitration
                    <br />
                    In the event the parties are not able to resolve any dispute
                    between them arising out of or concerning these Terms and
                    Conditions, or any provisions hereof, whether in contract,
                    tort, or otherwise at law or in equity for damages or any
                    other relief, then such dispute shall be resolved only by
                    final and binding arbitration pursuant to the Federal
                    Arbitration Act, conducted by a single neutral arbitrator
                    and administered by the American Arbitration Association, or
                    a similar arbitration service selected by the parties, in a
                    location mutually agreed upon by the parties. The
                    arbitrator's award shall be final, and judgment may be
                    entered upon it in any court having jurisdiction. In the
                    event that any legal or equitable action, proceeding or
                    arbitration arises out of or concerns these Terms and
                    Conditions, the prevailing party shall be entitled to
                    recover its costs and reasonable attorney's fees. The
                    parties agree to arbitrate all disputes and claims in
                    regards to these Terms and Conditions or any disputes
                    arising as a result of these Terms and Conditions, whether
                    directly or indirectly, including Tort claims that are a
                    result of these Terms and Conditions. The parties agree that
                    the Federal Arbitration Act governs the interpretation and
                    enforcement of this provision. The entire dispute, including
                    the scope and enforceability of this arbitration provision
                    shall be determined by the Arbitrator. This arbitration
                    provision shall survive the termination of these Terms and
                    Conditions.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Class Action Waiver
                    <br />
                    Any arbitration under these Terms and Conditions will take
                    place on an individual basis; class arbitrations and
                    class/representative/collective actions are not permitted.
                    THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE
                    OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A
                    PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE
                    AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A
                    PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further,
                    unless both you and FlyKatana agree otherwise, the
                    arbitrator may not consolidate more than one person's
                    claims, and may not otherwise preside over any form of a
                    representative or class proceeding.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Liability Disclaimer
                    <br />
                    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED
                    IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
                    TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                    INFORMATION HEREIN. LARTNOW LLC DBA FLYKATANA OR KATANA;
                    AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN
                    THE SITE AT ANY TIME.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    LARTNOW LLC DBA FLYKATANA OR KATANA; AND/OR ITS SUPPLIERS
                    MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                    AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                    SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED
                    ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
                    BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
                    SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                    WARRANTY OR CONDITION OF ANY KIND. LARTNOW LLC DBA FLYKATANA
                    OR KATANA; AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL
                    WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
                    SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
                    ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                    NON-INFRINGEMENT.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                    EVENT SHALL LARTNOW LLC DBA FLYKATANA OR KATANA; AND/OR ITS
                    SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                    WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS
                    OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
                    CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
                    DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
                    PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                    GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
                    OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT,
                    NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF LARTNOW
                    LLC DBA FLYKATANA OR KATANA; OR ANY OF ITS SUPPLIERS HAS
                    BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                    STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                    LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                    DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
                    ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY
                    OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                    DISCONTINUE USING THE SITE.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Termination/Access Restriction
                    <br /> Katana reserves the right, in its sole discretion, to
                    terminate your access to the Site and the related services
                    or any portion thereof at any time, without notice. To the
                    maximum extent permitted by law, this agreement is governed
                    by the laws of the State of Florida and you hereby consent
                    to the exclusive jurisdiction and venue of courts in Florida
                    in all disputes arising out of or relating to the use of the
                    Site. Use of the Site is unauthorized in any jurisdiction
                    that does not give effect to all provisions of these Terms,
                    including, without limitation, this section.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    You agree that no joint venture, partnership, employment, or
                    agency relationship exists between you and Katana as a
                    result of this agreement or use of the Site. Katana's
                    performance of this agreement is subject to existing laws
                    and legal process, and nothing contained in this agreement
                    is in derogation of Katana's right to comply with
                    governmental, court and law enforcement requests or
                    requirements relating to your use of the Site or information
                    provided to or gathered by Katana with respect to such use.
                    If any part of this agreement is determined to be invalid or
                    unenforceable pursuant to applicable law including, but not
                    limited to, the warranty disclaimers and liability
                    limitations set forth above, then the invalid or
                    unenforceable provision will be deemed superseded by a
                    valid, enforceable provision that most closely matches the
                    intent of the original provision and the remainder of the
                    agreement shall continue in effect.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Unless otherwise specified herein, this agreement
                    constitutes the entire agreement between the user and Katana
                    with respect to the Site and it supersedes all prior or
                    contemporaneous communications and proposals, whether
                    electronic, oral or written, between the user and Katana
                    with respect to the Site. A printed version of this
                    agreement and of any notice given in electronic form shall
                    be admissible in judicial or administrative proceedings
                    based upon or relating to this agreement to the same extent
                    and subject to the same conditions as other business
                    documents and records originally generated and maintained in
                    printed form. It is the express wish to the parties that
                    this agreement and all related documents be written in
                    English. This represents a knowing waiver of any and all 
                    liability on behalf of Lartnow LLC (dba as Katana) and any 
                    of its individual Members.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Changes to Terms
                    <br /> Katana reserves the right, in its sole discretion, to
                    change the Terms under which{" "}
                    <a href="https://www.flykatana.com" className="text-dark">
                      www.flykatana.com
                    </a>{" "}
                    is offered. The most current version of the Terms will
                    supersede all previous versions. Katana encourages you to
                    periodically review the Terms to stay informed of our
                    updates.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Contact Us
                    <br /> Katana welcomes your questions or comments regarding
                    the Terms:
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Lartnow LLC dba "Katana" or "FlyKatana"
                    <br /> PO BOX 18191
                    <br />
                    Tampa, Florida 33629
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Email Address:
                    <br />{" "}
                    <a href="mailto:bv@flykatana.com">bv@flykatana.com</a>
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Telephone number:
                    <br /> <a href="callto:(929) 318-9292">(929) 318-9292</a>
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Effective as of May 17, 2021
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" sm="12" className="mb-2">
                  <h2>PRIVACY POLICY</h2>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg="12" md="12" sm="12">
                  <p class="text-dark font-weight-bold text-justify">
                    Protecting your private information is our priority. This
                    Statement of Privacy applies to www.flykatana.com, and
                    Lartnow LLC dba Katana or FlyKatana and governs data
                    collection and usage. For the purposes of this Privacy
                    Policy, unless otherwise noted, all references to Lartnow
                    LLC dba Katana or FlyKatana include www.flykatana.com,
                    Katana, and FlyKatana. The Katana website is a Travel
                    coordination and communication site. By using the Katana
                    website, you consent to the data practices described in this
                    statement.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Collection of your Personal Information
                    <br />
                    In order to better provide you with products and services
                    offered, Katana may collect personally identifiable
                    information, such as your:
                  </p>
                  <p class="text-dark font-weight-bold text-justify">
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First
                    and Last Name
                    <br />
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E-mail
                    Address
                    <br />
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone
                    Number
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    If you purchase Katana's products and services, we collect
                    billing and credit card information. This information is
                    used to complete the purchase transaction.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Please keep in mind that if you directly disclose personally
                    identifiable information or personally sensitive data
                    through Katana's public message boards, this information may
                    be collected and used by others.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    We do not collect any personal information about you unless
                    you voluntarily provide it to us. However, you may be
                    required to provide certain personal information to us when
                    you elect to use certain products or services. These may
                    include: (a) registering for an account; (b) entering a
                    sweepstakes or contest sponsored by us or one of our
                    partners; (c) signing up for special offers from selected
                    third parties; (d) sending us an email message; (e)
                    submitting your credit card or other payment information
                    when ordering and purchasing products and services. To wit,
                    we will use your information for, but not limited to,
                    communicating with you in relation to services and/or
                    products you have requested from us. We also may gather
                    additional personal or non-personal information in the
                    future.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Use of your Personal Information
                    <br /> Katana collects and uses your personal information to
                    operate and deliver the services you have requested.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Katana may also use your personally identifiable information
                    to inform you of other products or services available from
                    FlyKatana and its affiliates.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Sharing Information with Third Parties
                    <br /> Katana may sell, rent, or lease customer information
                    to third parties for the following reasons:
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    To provide relevant information related to travel
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Katana may, from time to time, contact you on behalf of
                    external business partners about a particular offering that
                    may be of interest to you. In those cases, your unique
                    personally identifiable information (e-mail, name, address,
                    telephone number) is transferred to the third party. Katana
                    may share data with trusted partners to help perform
                    statistical analysis, send you email or postal mail, provide
                    customer support, or arrange for deliveries. All such third
                    parties are prohibited from using your personal information
                    except to provide these services to Katana, and they are
                    required to maintain the confidentiality of your
                    information.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Katana may disclose your personal information, without
                    notice, if required to do so by law or in the good faith
                    belief that such action is necessary to: (a) conform to the
                    edicts of the law or comply with legal process served on
                    Katana or the site; (b) protect and defend the rights or
                    property of Katana; and/or (c) act under exigent
                    circumstances to protect the personal safety of users of
                    Katana, or the public.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Opt-Out of Sale or Disclosure of Personal Information to
                    Third Parties
                    <br /> In connection with any personal information we may
                    sell or disclose to a third party for a business purpose,
                    <br /> you have the right to know:
                    <br />
                    •&nbsp;&nbsp;&nbsp;&nbsp;The categories of personal
                    information about you that we sold and the categories of
                    third parties to whom the personal information was sold; and{" "}
                    <br />
                    •&nbsp;&nbsp;&nbsp;&nbsp;The categories of personal
                    information that we disclosed about you for a business
                    purpose.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    You have the right under the California Consumer Privacy Act
                    of 2018 (CCPA) and certain other privacy and data protection
                    laws, as applicable, to opt-out of the sale or disclosure of
                    your personal information. If you exercise your right to
                    opt-out of the sale or disclosure of your personal
                    information, we will refrain from selling or disclosing your
                    personal information, unless you subsequently provide
                    express authorization for the sale or disclosure of your
                    personal information. To opt-out of the sale or disclosure
                    of your personal information, visit this Web page
                    _________________.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Tracking User Behavior
                    <br /> Katana may keep track of the websites and pages our
                    users visit within FlyKatana, in order to determine what
                    Katana services are the most popular. This data is used to
                    deliver customized content and advertising within Katana to
                    customers whose behavior indicates that they are interested
                    in a particular subject area.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Automatically Collected Information
                    <br /> Information about your computer hardware and software
                    may be automatically collected by Katana. This information
                    can include: your IP address, browser type, domain names,
                    access times and referring website addresses. This
                    information is used for the operation of the service, to
                    maintain quality of the service, and to provide general
                    statistics regarding use of the Katana website.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Use of Cookies
                    <br /> The Katana website may use "cookies" to help you
                    personalize your online experience. A cookie is a text file
                    that is placed on your hard disk by a web page server.
                    Cookies cannot be used to run programs or deliver viruses to
                    your computer. Cookies are uniquely assigned to you, and can
                    only be read by a web server in the domain that issued the
                    cookie to you.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    One of the primary purposes of cookies is to provide a
                    convenience feature to save you time. The purpose of a
                    cookie is to tell the Web server that you have returned to a
                    specific page. For example, if you personalize Katana pages,
                    or register with Katana site or services, a cookie helps
                    Katana to recall your specific information on subsequent
                    visits. This simplifies the process of recording your
                    personal information, such as billing addresses, shipping
                    addresses, and so on. When you return to the same Katana
                    website, the information you previously provided can be
                    retrieved, so you can easily use the Katana features that
                    you customized.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    You have the ability to accept or decline cookies. Most Web
                    browsers automatically accept cookies, but you can usually
                    modify your browser setting to decline cookies if you
                    prefer. If you choose to decline cookies, you may not be
                    able to fully experience the interactive features of the
                    Katana services or websites you visit.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Links
                    <br /> This website contains links to other sites. Please be
                    aware that we are not responsible for the content or privacy
                    practices of such other sites. We encourage our users to be
                    aware when they leave our site and to read the privacy
                    statements of any other site that collects personally
                    identifiable information.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Security of your Personal Information
                    <br /> Katana secures your personal information from
                    unauthorized access, use, or disclosure. Katana uses the
                    following methods for this purpose:
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SSL
                    Protocol
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    When personal information (such as a credit card number) is
                    transmitted to other websites, it is protected through the
                    use of encryption, such as the Secure Sockets Layer (SSL)
                    protocol.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    We strive to take appropriate security measures to protect
                    against unauthorized access to or alteration of your
                    personal information. Unfortunately, no data transmission
                    over the Internet or any wireless network can be guaranteed
                    to be 100% secure. As a result, while we strive to protect
                    your personal information, you acknowledge that: (a) there
                    are security and privacy limitations inherent to the
                    Internet which are beyond our control; and (b) security,
                    integrity, and privacy of any and all information and data
                    exchanged between you and us through this Site cannot be
                    guaranteed.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    We strive to take appropriate security measures to protect
                    against unauthorized access to or alteration of your
                    personal information. Unfortunately, no data transmission
                    over the Internet or any wireless network can be guaranteed
                    to be 100% secure. As a result, while we strive to protect
                    your personal information, you acknowledge that: (a) there
                    are security and privacy limitations inherent to the
                    Internet which are beyond our control; and (b) security,
                    integrity, and privacy of any and all information and data
                    exchanged between you and us through this Site cannot be
                    guaranteed.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Right to Deletion
                    <br /> Subject to certain exceptions set out below, on
                    receipt of a verifiable request from you, we will:
                    <br />
                    •&nbsp;&nbsp;&nbsp;&nbsp;Delete your personal information
                    frcords; records; and
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Direct any service providers
                    to delete your personal information from their records.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Please note that we may not be able to comply with requests
                    to delete your personal information if it is necessary to:
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Complete the transaction for
                    which the personal information was collected, fulfill the
                    terms of a written warranty or product recall conducted in
                    accordance with federal law, provide a good or service
                    requested by you, or reasonably anticipated within the
                    context of our ongoing business relationship with you, or
                    otherwise perform a contract between you and us;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Detect security incidents,
                    protect against malicious, deceptive, fraudulent, or illegal
                    activity; or prosecute those responsible for that activity;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Debug to identify and repair
                    errors that impair existing intended functionality;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Exercise free speech, ensure
                    the right of another consumer to exercise his or her right
                    of free speech, or exercise another right provided for by
                    law;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Comply with the California
                    Electronic Communications Privacy Act;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Engage in public or
                    peer-reviewed scientific, historical, or statistical
                    research in the public interest that adheres to all other
                    applicable ethics and privacy laws, when our deletion of the
                    information is likely to render impossible or seriously
                    impair the achievement of such research, provided we have
                    obtained your informed consent;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Enable solely internal uses
                    that are reasonably aligned with your expectations based on
                    your relationship with us;
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Comply with an existing
                    legal obligation; or
                    <br /> •&nbsp;&nbsp;&nbsp;&nbsp;Otherwise use your personal
                    information, internally, in a lawful manner that is
                    compatible with the context in which you provided the
                    information.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Children Under Thirteen
                    <br /> Katana does not knowingly collect personally
                    identifiable information from children under the age of
                    thirteen. If you are under the age of thirteen, you must ask
                    your parent or guardian for permission to use this website.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Opt-Out & Unsubscribe from Third Party Communications
                    <br /> We respect your privacy and give you an opportunity
                    to opt-out of receiving announcements of certain
                    information. Users may opt-out of receiving any or all
                    communications from third-party partners of FlyKatana by
                    contacting us here:
                    <br />- Email:{" "}
                    <a href="mailto:bv@flykatana.com">bv@flykatana.com</a>
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    E-mail Communications
                    <br /> From time to time, Katana may contact you via email
                    for the purpose of providing announcements, promotional
                    offers, alerts, confirmations, surveys, and/or other general
                    communication. In order to improve our Services, we may
                    receive a notification when you open an email from Katana or
                    click on a link therein.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    If you would like to stop receiving marketing or promotional
                    communications via email from Katana, you may opt out of
                    such communications by replying STOP.
                  </p>
                  <p class="text-dark font-weight-bold text-justify">
                    External Data Storage Sites
                    <br /> We may store your data on servers provided by third
                    party hosting vendors with whom we have contracted.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Changes to this Statement
                    <br /> Katana reserves the right to change this Privacy
                    Policy from time to time. We will notify you about
                    significant changes in the way we treat personal information
                    by sending a notice to the primary email address specified
                    in your account, by placing a prominent notice on our
                    website, and/or by updating any privacy information. Your
                    continued use of the website and/or Services available after
                    such modifications will constitute your: (a) acknowledgment
                    of the modified Privacy Policy; and (b) agreement to abide
                    and be bound by that Policy.
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Contact Information
                    <br /> Katana welcomes your questions or comments regarding
                    this Statement of Privacy. If you believe that Katana has
                    not adhered to this Statement, please contact Katana at:
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Lartnow LLC dba Katana or FlyKatana
                    <br /> PO BOX 18191
                    <br /> Tampa, Florida 33629
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Email Address:
                    <br />{" "}
                    <a href="mailto:bv@flykatana.com">bv@flykatana.com</a>
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Telephone number:
                    <br /> <a href="callto:(929) 318-9292">(929) 318-9292</a>
                  </p>
                  <br />
                  <p class="text-dark font-weight-bold text-justify">
                    Effective as of May 17, 2021
                  </p>
                  <br />
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </>
    );
}

export default Legal
