import React, { useEffect } from "react";
import { useOutlookData } from "../utils/OutLookPeopleApi";
import { useLocalStorage } from '../utils/useLocalStorage'

function AuthorizeOutlook() {

    const { GetAccessTokenByAuthCode } = useOutlookData();

    const[ user, SetUser] = useLocalStorage("auth", {});

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get("code");
      const state = queryParams.get("state");
      if (code) {
        setTimeout(function () {
          GetAccessTokenByCode(code, state);
        }, 300);
      }
    }, []);

    async function GetAccessTokenByCode(code, state) {
      const response = await GetAccessTokenByAuthCode(user.userid, code);
      if (response) {
        let userDetails = user;
        userDetails.is_outlook_linked = true;
        SetUser(userDetails);
        if (state !== null || state != undefined || state != "") {
          window.location.href = state;
        } else {
          window.location.href = "/MyFriends";
        }      }
    }

    return (
        <div className="text-center">
            <h1>Outlook Authorized Successfully.</h1>
        </div>
    )
}

export default AuthorizeOutlook
