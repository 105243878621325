
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { FaTimes, FaPlane } from "react-icons/fa";
import airportData from "../data/airport";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import {
  FormGroup,
} from "reactstrap";
import './City.css'

function City({ type, code, setCode, Page }) {
  const { t } = useTranslation();
  const [cityCountry, setCityCountry] = useState("");
  const [filterdata, setFilterData] = useState([]);


  const airportSuggestionRef = useRef(
    airportData.map((item) => ({
      label: `${item.city}: ${item.IATA}`,
      value: item.IATA,
      country: item.country,
    }))
  );
  const options = airportSuggestionRef.current;
  useEffect(() => {
    setFilterData(options);
  }, []);

  useEffect(() => {
    if (code && airportData) {
      const airport = airportData.find((item) => item.IATA === code);
      setCityCountry(`${airport.IATA}-${airport.city}, ${airport.country}`);
    } else {
      setCityCountry("");
    }
  }, [code, airportData]);

  const onClickInput = () => {
    setCode("");
  }

  const onTextChange = (event) => {
    setCityCountry(event.target.value);
    const filterdata = options.filter(
      (opt) =>
        opt.label.toLowerCase().includes(event.target.value.toLowerCase()) ||
        opt.value.toLowerCase().includes(event.target.value.toLowerCase()) ||
        opt.country.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilterData(filterdata);
  }

  const SelectOption = (event) => {
    setCode(event.target.attributes.getNamedItem("data-value").value);
  };

  return (
    <div>
      <FormGroup className="mb-3">
        <FormControl fullWidth>
          <InputLabel htmlFor={"city_" + type}>
            {type === "from" ? "From" : "To"}
          </InputLabel>
          <Input
            id={"city_" + type}
            value={cityCountry}
            onChange={onTextChange}
            onClick={onClickInput}
            placeholder="Anywhere"
            autoComplete="off"
            startAdornment={
              <InputAdornment position="start">
                {type === "from" ? (
                  <img
                    alt="..."
                    style={{ height: "15px", width: "22.5px" }}
                    src={
                      require("../assets/images/icons/plane-take-off.png")
                        .default
                    }
                  />
                ) : (
                  <img
                    alt="..."
                    style={{ height: "18px", width: "22.5px" }}
                    src={
                      require("../assets/images/icons/plane-landing.png")
                        .default
                    }
                  />
                )}
              </InputAdornment>
            }
          />
        </FormControl>
        {code !== null && type === "to" && Page !== "Book" && (
          <FaTimes
            style={{
              position: "absolute",
              bottom: "20px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={(e) => setCode(null)}
          />
        )}
        {code === "" && (
          <div className="Dropdown">
            <ul>
              {filterdata &&
                filterdata.map((item) => {
                  return (
                    <li
                      key={item.label}
                      data-value={item.value}
                      onClick={SelectOption}
                    >
                      {item.label}
                      {/* <div>
                        <div>
                          <span className="h3" data-value={item.value}>
                            {item.label}
                          </span>
                          <span
                            className="h4"
                            style={{ float: "right" }}
                            data-value={item.value}
                          >
                            {item.value}
                          </span>
                        </div>
                      </div> */}
                    </li>
                  );
                })}
              {filterdata.length < 1 && <li>No Data Found</li>}
            </ul>
          </div>
        )}
      </FormGroup>
    </div>
  );
}

export default City;
