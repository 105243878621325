import axios from "../libs/axios";

const GetAllFriend = async (mobile) => {
  const { data } = await axios.request({
    url: "/Friends",
    method: "post",
    data: { mobile: mobile },
  });
  return data;
};

const RemoveFriendApi = async (friendsData) => {
  const { data } = await axios.request({
    url: "/RemoveFriend",
    method: "post",
    data: friendsData,
  });
  return data;
};

const InviteFriend = async (friendsData) => {
  const { data } = await axios.request({
    url: "/CreateConnection",
    method: "post",
    data: friendsData,
  });
  return data;
};

const AddFriend = async (friendsData) => {
  const { data } = await axios.request({
    url: "/CreateMemberConnection",
    method: "post",
    data: friendsData,
  });
  return data;
};

const LeaveGroup = async (groupData) => {
  const { data } = await axios.request({
    url: "/LeaveGroup",
    method: "post",
    data: groupData,
  });
  return data;
};

const InvitesSent = async (userid) => {
  const { data } = await axios.request({
    url: "/InvitesSent",
    method: "post",
    data: { userid: userid },
  });
  return data;
};

const AddAllKatanaMembers = async(membersdata)=>{
  const { data } = await axios.request({
    url: "/AddAllKatanaMembers",
    method: "post",
    data: membersdata,
  });
  return data;
}

export const useFriendsData = () => ({
  GetAllFriend,
  RemoveFriendApi,
  InviteFriend,
  AddFriend,
  LeaveGroup,
  InvitesSent,
  AddAllKatanaMembers,
});