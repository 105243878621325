import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import reactS3 from "react-s3";

// import { aws } from '../../Keys'
import { useLocalStorage } from "../utils/useLocalStorage";
import { Card, CardBody, Input, Label, Button, Alert } from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardHeader,
} from "reactstrap";
import { FaRegTimesCircle } from "react-icons/fa";
import { FormControl, Snackbar } from "@material-ui/core";
import { authAndUserData } from "../data/AuthAndUserData";
import Camera from "../components/MyProfile/Camera";
import LoginImage from "../assets/images/icons/ninja3.png";
import HomeAway from "../components/HomeAway";
import Compress from "compress.js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function MyProfile({ title, openmodal }) {
  const [user, setUser] = useLocalStorage("auth", {});
  const [email, setEmail] = useState(user.email);
  const [mobile, setMobile] = useState(user.mobile);
  const [name, setName] = useState(user.name);
  const [home, setHome] = useState(
    user.home === "" ? null : user.home + ":" + user.home_code
  );
  const [away, setAway] = useState(user.away === "" ? null : user.away);
  const [subscription_end_date, SetSubscription] = useState(
    user.subscription_end
  );
  const [subscription_type, SetSubscriptionType] = useState(
    user.subscription_type
  );
  const [subscription_type_new, SetSubscriptionTypeNew] = useState("");
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProfileUpdate, setProfileUpdated] = useState(false);
  const {
    UpdateProfile,
    PostAuth,
    ProfilePicture,
    GenerateS3PreSignedUrl,
    CancelSubscription,
    ChangeSubscription,
  } = authAndUserData();
  const [loginImage, SetLoginImage] = useState(LoginImage);
  const [fileData, SetFileData] = useState(null);
  const { GetUserDetailsForHome } = authAndUserData();
  var { subscription_end } = "";
  //const [modal, setModal] = useState(false);
  const [modal, setModal] = useState(openmodal ? openmodal : false);
  const [isSubscriptionChanged, setSubscriptionChanged] = useState(false);
  const [isSignUpSuccess, setSignUpSuccess] = useState(false);
  const [modal_cancel, setCancelModal] = useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const navigatePlans = () => (window.location.href = "/plans"); //history.push('/plans');

  // Toggle for Cancel Modal
  const toggle_cancel = () => setCancelModal(!modal_cancel);

  async function resizeImageFn(file) {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 300, // the max width of the output image, defaults to 1920px
      maxHeight: 300, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
    resizedFiile.name = file.name;
    return resizedFiile;
  }

  const config = {
    bucketName: "katana-images",
    dirName: "profile_pictures",
    region: "us-east-1",
    accessKeyId: "AKIAYJTGGPZLK7IG6X5D",
    secretAccessKey: "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",
  };

  useEffect(() => {
    GetSubscriptionEndDate();
    if (user.profile_pic_path === null || user.profile_pic_path === "") {
      SetLoginImage(LoginImage);
    } else {
      SetLoginImage(user.profile_pic_path);
    }
    if (typeof user.userid === "undefined") {
      history.push("/");
    }
    setTimeout(function () {
      setProfileUpdated(false);
    }, 5000);
  }, [isProfileUpdate]);

  async function OnUpdateProfile(event) {
    if (ValidateData()) {
      if (
        await UpdateProfile(email, name, home, away, user.userid, user.password)
      ) {
        const response = await PostAuth(user.mobile, user.password);
        setProfileUpdated(true);
        setUser(response);
        setErrorMessage("");
        setIsError(false);
      }
    }
  }

  async function OnCancelSubscription(event) {
    const response = await CancelSubscription(user.subscription_id);
    //console.log("Cancel resp: "+JSON.stringify(response.data));
    //console.log(response.data.status);
    if (response.data.status == true) {
      setErrorMessage("");
      setIsError(false);
      toggle_cancel();
      toggle();
    }
  }

  async function OnChangeSubscription(event) {
    const response = await ChangeSubscription(
      user.subscription_id,
      user.subscription_type,
      user.userid
    );
    //console.log("Cancel resp: "+JSON.stringify(response.data));
    //console.log(response.data.status);
    if (response.data.status == true) {
      setErrorMessage("");
      setIsError(false);
      setSubscriptionChanged(true);
      //toggle_cancel();
      //toggle();
      GetSubscriptionEndDate();
    }
  }

  async function GetSubscriptionEndDate() {
    const userData = await GetUserDetailsForHome(user.userid);
    //console.log("User Data: "+JSON.stringify(userData));
    setUser(userData);
    //window.location.href = "/myProfile";
    //console.log(String(userData.subscription_end));
    SetSubscription(userData.subscription_end);
    SetSubscriptionType(userData.subscription_type);
    if (userData.subscription_type == "Annual") {
      SetSubscriptionTypeNew("Monthly");
    }
    if (userData.subscription_type == "Monthly") {
      SetSubscriptionTypeNew("Annual");
    }
    return String(userData.subscription_end);
  }
  //GetSubscriptionEndDate().then((resp) => {window.location = "/myProfile";});

  function ValidateData() {
    if (email === "" || email === undefined || email === null) {
      setErrorMessage("Please input your email");
      setIsError(true);
      return false;
    } else if (name === "" || name === undefined || name === null) {
      setErrorMessage("Please input your name");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
    //  else if (home === "" || home === undefined || home === null) {
    //    setErrorMessage("Please input your home");
    //    setIsError(true);
    //    return false;
    //  }
    //  else if (away === "" || away === undefined || away === null) {
    //    setErrorMessage("Please input your away");
    //    setIsError(true);
    //    return false;
    //  }
  }

  function SelectProfilePicture(e) {
    document.getElementById("selectImage").click();
  }

  function UploadImage(e) {
    var MyFile = e.target.files[0];
    var ext = MyFile.name.substr(MyFile.name.lastIndexOf(".") + 1);
    const myNewFile = new File([MyFile], user.userid + "-profile." + ext, {
      type: MyFile.type,
    });
    if (myNewFile.type.includes("image")) {
      SetFileData(myNewFile);
      SetLoginImage(URL.createObjectURL(e.target.files[0]));
      setErrorMessage("");
      setIsError(false);
    } else {
      setErrorMessage("You can only upload image files");
      setIsError(true);
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      return fileInfo;
    });
  };

  //OLD LOGIC ACCESS KEYS ON FRONTEND
  async function OnUploadImageOld(e) {
    const rezizedFile = await resizeImageFn(fileData);
    console.log(rezizedFile);
    const data = await reactS3.uploadFile(rezizedFile, config);

    if (data.result) {
      const formData = {
        profileimage: data.location,
        userid: user.userid,
      };
      const response = await ProfilePicture(formData);
      if (response && response.length > 0) {
        setUser(response[0]);
        SetFileData(null);
        window.location = "/myProfile";
      }
    }
  }

  //Added on 21-02-2024 to generate Pre signed url from API and upload using that.
  async function OnUploadImage(e) {
    const resizedFile = await resizeImageFn(fileData);
    console.log(resizedFile);

    // Get presigned URL from your server
    try {
      const response = await GenerateS3PreSignedUrl({ fileName: resizedFile.name, fileType: resizedFile.type, uploadFolder: 'profile_pictures' });
      const data = response;

      // Upload the file to S3 using the presigned URL
      const uploadResponse = await fetch(data.url, {
        method: 'PUT',
        body: resizedFile,
        headers: {
          'Content-Type': resizedFile.type
        }
      });

      if (uploadResponse.ok) {
        console.log('File successfully uploaded to S3');

        const formData = {
          profileimage: data.url.split('?')[0], // URL without query parameters
          userid: user.userid,
        };
        const profileResponse = await ProfilePicture(formData);

        if (profileResponse && profileResponse.length > 0) {
          setUser(profileResponse[0]);
          SetFileData(null);
          window.location = "/myProfile";
        }
      } else {
        console.log('Failed to upload file');
      }
    } catch (error) {
      console.error('Error in file upload', error);
    }
  }


  return (
    <>
      <Card>
        {/* <CardHeader className="text-center">
            <h2>{title}</h2>
          </CardHeader> */}
        <CardBody>
          {isError && <Alert className="alertYellow">{errorMessage}</Alert>}
          {isProfileUpdate && (
            <Alert className="alertYellow">Profile updated successfully.</Alert>
          )}
          <FormControl fullWidth>
            <div className="text-center">
              <input
                type="file"
                onChange={UploadImage}
                multiple={false}
                accept="image/*"
                id="selectImage"
                hidden
              ></input>
              <br />
              <div className="text-center">
                <Camera
                  SetLoginImage={SetLoginImage}
                  user={user}
                  SetFileData={SetFileData}
                  loginImage={loginImage}
                />
              </div>
              {fileData ? (
                <Button
                  style={{ borderRadius: "20px" }}
                  onClick={OnUploadImage}
                  className="btn btnYellow mt-2"
                >
                  Upload Image
                </Button>
              ) : (
                <></>
              )}
            </div>
          </FormControl>
          <Label>
            <b>&nbsp;&nbsp;Name</b>
          </Label>
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <br />
          <Label>
            <b>&nbsp;&nbsp;Email</b>
          </Label>
          <Input
            type="text"
            placeholder="Email"
            readOnly={true}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <br />
          <Label>
            <b>&nbsp;&nbsp;Mobile</b>
          </Label>
          <Input
            type="text"
            placeholder="Mobile"
            readOnly={true}
            value={"+" + mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
          <br />
          <Label>
            <b>&nbsp;&nbsp;Home (Frequent Departure City)</b>
          </Label>
          {/* <Input
              type="text"
              placeholder="Home"
              value={home}
              onChange={(e) => {
                setHome(e.target.value);
              }}
            /> */}

          <HomeAway
            type="Home"
            code={home}
            setCode={setHome}
            Input={Input}
            page="Profile"
          />

          <br />
          <Label>
            <b>&nbsp;&nbsp;Away (Frequent Arrival City)</b>
          </Label>
          {/* <Input
              type="text"
              placeholder="Away"
              value={away}
              onChange={(e) => {
                setAway(e.target.value);
              }}
            /> */}

          <HomeAway
            type="Away"
            code={away}
            setCode={setAway}
            Input={Input}
            page="Profile"
          />
          <br />
          <Label>
            <b>&nbsp;&nbsp;Subscription Type</b>
          </Label>
          <Input
            type="text"
            placeholder="Subscription Type"
            value={subscription_type}
            readOnly={true}
          />
          <br />
          <Label>
            <b>&nbsp;&nbsp;Renewal Date</b>
          </Label>
          <Input
            type="text"
            placeholder="Renewal Date"
            value={subscription_end_date}
            readOnly={true}
          />
          <br />
          <br />
          <div className="text-center mb-3">
            <Button
              name="login"
              color="dark"
              style={{ borderRadius: "20px" }}
              onClick={OnUpdateProfile}
              className="btn btn-block"
            >
              Update Profile
            </Button>
          </div>
          <br />
          <div className="text-center mb-3">
            <Button
              name="login"
              color="#525f7f"
              style={{
                borderRadius: "20px",
                "border-color": "#525f7f",
                border: "2px solid",
              }}
              onClick={navigatePlans} //{toggle}
              className="btn btn-block"
            >
              Change Subscription
            </Button>
          </div>
          <Modal
            isOpen={modal}
            toggle={toggle}
            backdrop={"static"}
            keyboard={false}
            centered
          >
            <ModalBody className="p-0">
              <Card className="shadow border-0">
                <CardHeader className="p-0">
                  <div className="text-right p-2">
                    <FaRegTimesCircle onClick={toggle} cursor="pointer" />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-2">
                  <div className="text-center mb-3">
                    <div className="text-right p-2">
                      <br />
                      <div className="text-center mb-3">
                        <Button
                          name="login"
                          color="dark"
                          style={{
                            borderRadius: "20px",
                            "border-color": "#525f7f",
                            border: "2px solid",
                          }}
                          onClick={OnCancelSubscription}
                          className="btn btn-block"
                        >
                          Cancel Subscription
                        </Button>
                      </div>
                      <br />
                      {subscription_type != "Trial" && (
                        <div className="text-center mb-3">
                          <Button
                            name="login"
                            color="dark"
                            style={{
                              borderRadius: "20px",
                              "border-color": "#525f7f",
                              border: "2px solid",
                            }}
                            onClick={OnChangeSubscription}
                            className="btn btn-block"
                          >
                            Change to {subscription_type_new} Plan
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  {isSubscriptionChanged && (
                    <Alert className="alertYellow">
                      Subscription changed to {subscription_type} plan.
                    </Alert>
                  )}
                  {isSignUpSuccess && (
                    <Alert className="alertYellow">
                      Subscription changed to {subscription_type} plan. Signed
                      up successfully. Please enter your credentials to logIn.
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
          <Snackbar
            autoHideDuration={2000}
            toggle={toggle_cancel}
            color="dark"
            open={modal_cancel}
            onClose={toggle_cancel}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            keyboard={false}
            style={{ height: "100%", width: "100%" }}
          >
            <Alert
              className="text-center"
              color="dark"
              style={{ color: "rgb(255, 230, 0)" }}
            >
              <CheckCircleOutlineIcon />
              <br />
              {"Subscription cancelled successfully!"}
            </Alert>
          </Snackbar>
        </CardBody>
      </Card>
    </>
  );
}

export default MyProfile;
