import React, { useState } from "react";
import { FaAngleDoubleDown } from 'react-icons/fa';
// import "./CardComponent.css";
import image1 from "../assets/footerimage/marketwatch-ar21.png"
import image2 from "../assets/footerimage/logo-white-panews.webp"
import image3 from "../assets/footerimage/Timage.jpg"
import image4 from "../assets/footerimage/pngegg.png"
import Testimonials from "../pages/Testimonials";
const CardComponent = () => {
  const [expandedCard, setExpandedCard] = useState(-1); // Initial value -1 means no card expanded

  const data = [
    {
      title: "BROWSE",
      imageSrc: require("../assets/images/Browse.jpg").default,
      description:
        "Katana's comprehensive database empowers you to effortlessly locate available private plane seats for purchase, uncover enticing empty leg charter opportunities at competitive rates, and effortlessly list any surplus seats from your private charter for sale.",
    },
    {
      title: "BOOK",
      imageSrc: require("../assets/images/Book.jpg").default,
      description:
        "Katana easily connects you with the carrier, member, or operator of the flight you've chosen. From there, you can simply select the number of seats you require, arrange payment, and you're ready to go!",
    },
    {
      title: "RELAX",
      imageSrc: require("../assets/images/Relax.jpg").default,
      description:
        "Reach your destination more quickly, conveniently, and affordably by sharing with fellow Katana Members.",
    },
  ];
  const footerimage = [
    {
      src: image1,
      alt: "Image 1"
    },
    {
      src: image2,
      alt: "Image 2"
    },
    {
      src: image3,
      alt: "Image 3"
    }, {
      src: image4,
      alt: "image 4"
    }
  ];

  return (
    <div className="home-card-container">
      <>
        {data.map((item, index) => (
          <div
            key={index}
            className={`homecard ${expandedCard === index ? "expanded" : ""}`}
          >
            <div className="home-card-image-wrapper">
              <img src={item.imageSrc} alt={item.altText} />
              <div className="overlay-placeholder"></div>

              <h3 className="home-card-title">{item.title}</h3>
              <div
                className={`home-card-arrow-icon ${expandedCard === index ? "expanded" : ""
                  }`}
                onClick={() =>
                  setExpandedCard(expandedCard === index ? -1 : index)
                }
              >
                <FaAngleDoubleDown />
              </div>
            </div>
            <div className="home-card-content">
              <div className="home-card-description">{item.description}</div>
            </div>
          </div>
        ))}
        <div style={{ width: '100%' }}><Testimonials /></div>
        <div className="Footer-img-logo">
          {footerimage.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
            />
          ))}
        </div>
      </>
    </div>
  );
};

export default CardComponent;