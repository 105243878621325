import { useEffect, useState, useRef } from "react";
import { Grid, Card } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { useLocalStorage } from "../utils/useLocalStorage";
import PathArea from "../components/PathArea";
import Search from "../components/HomePage/Search";
import Book from "../components/HomePage/Book";
import FlightList from "../components/FlightList";
import "./HomePage.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { myElementRef } from "../layouts/Header";
import { Helmet } from "react-helmet";
import HomePageCards from "../components/HomeCardsOld";
import CardComponent from "../components/HomeCardsNew";

function HomePage() {
  const [user] = useLocalStorage("auth", {});
  const [pathType, setPathType] = useState("search");
  const [unregistedFlightsData, setFlightData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState(0);
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const [searchClick, setSearchClick] = useState(false);
  var [headerHeight, setHeaderHeight] = useState(125);
  const scrollToRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;
    if (card && card.scrollHeight > card.clientHeight) {
      card.style.height = "auto";
    }
  }, []); // E

  // Use effect to check if isSearchClick is set to true
  useEffect(() => {
    if (searchClick && scrollToRef.current) {
      // Get the top position of the scrollToRef element
      const topPosition =
        scrollToRef.current.getBoundingClientRect().top + window.pageYOffset;
      // Adjust for the header height
      const adjustedPosition = topPosition - headerHeight;
      // Scroll to the adjusted position
      window.scrollTo({
        top: adjustedPosition,
        behavior: "smooth",
      });
    }
  }, [searchClick]);

  useEffect(() => {
    if (searchClick) {
      const chatEl = document
        .querySelector(".sticky-component")
        .getBoundingClientRect();
      setSidebarTop(chatEl.top);
    }
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  const isSticky = (e) => {
    const chatEl = document.querySelector(".sticky-component");
    const scrollTop = window.scrollY;
    setHeaderHeight(document.getElementById("top-header").offsetHeight);
    //      console.log("HeadHeight: "+document.getElementById('top-header').offsetHeight);
    headerHeight = document.getElementById("top-header").offsetHeight;
    // console.log("scrollTop: "+scrollTop);
    // console.log("sidebarTop: "+sidebarTop);
    // console.log("Header height: "+headerHeight);

    if (scrollTop >= sidebarTop - headerHeight) {
      document.documentElement.style.setProperty(
        "--sticky-top",
        headerHeight + "px"
      );
      chatEl.classList.add("is-sticky");
    } else {
      chatEl.classList.remove("is-sticky");
    }
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    const h1 = document.createElement("h1");
    h1.innerText = "Book Shared Private Flights";
    h1.style.display = "none";
    body.insertBefore(h1, body.firstChild);
  }, []);

  useEffect(() => {
    setFilteredData(unregistedFlightsData);
    handleChange(null, 0);
    //setValue(1);
    //setFilteredData(unregistedFlightsData.filter((x) => x.is_posted == true && x.is_charter == false))
  }, [unregistedFlightsData]);

  const handleChange = (event, newValue) => {
    //setFilteredData(unregistedFlightsData);
    setValue(newValue);
    console.log("New Value: " + newValue);
    // //console.log("Flight list: "+unregistedFlightsData);
    // if (newValue == 0) {
    //   setFilteredData(unregistedFlightsData);
    // }
    // if (newValue == 1) {
    //   setFilteredData(
    //     unregistedFlightsData.filter(
    //       (x) => x.is_posted == true && x.is_charter == false
    //     )
    //   );
    // }
    // if (newValue == 2) {
    //   setFilteredData(
    //     unregistedFlightsData.filter((x) => x.is_charter == true)
    //   );
    // }
    // if (newValue == 3) {
    //   setFilteredData(
    //     unregistedFlightsData.filter((x) => x.is_posted == false)
    //   );
    // }
    // console.log("Filtered Data: " + JSON.stringify(filteredData));

    let newFilteredData;
    switch (newValue) {
      case 0:
        newFilteredData = unregistedFlightsData;
        break;
      case 1:
        newFilteredData = unregistedFlightsData.filter(
          (x) => x.is_posted === true && x.is_charter === false
        );
        break;
      case 2:
        newFilteredData = unregistedFlightsData.filter(
          (x) => x.is_charter === true
        );
        break;
      case 3:
        newFilteredData = unregistedFlightsData.filter(
          (x) => x.is_posted === false
        );
        break;
      default:
        newFilteredData = unregistedFlightsData;
    }

    setFilteredData(newFilteredData);
    setValue(newValue);
    console.log("Filtered Data Array: " + JSON.stringify(newFilteredData));
    console.log("Filtered Data: " + JSON.stringify(filteredData));
  };

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        defaultTitle="Book Shared Private Flights | Fly Katana"
      >
        <meta
          name="description"
          content="Fly Katana provides private plane splitting services for a more efficient and cost-effective travel experience. Book your flight now!"
        />
        <link rel="canonical" href="https://www.flykatana.com/"></link>
        <title>Book Shared Private Flights | Fly Katana</title>
      </Helmet>
      {pathType === "book" && (
        <>
          <Row>
            <Col
              lg={{ size: 12 }}
              md={{ size: 12 }}
              sm="12"
              className="text-center"
            >
              <Grid className="imgBackground">
                <div className="fullWidthHomeSearch m-3">
                  <Row>
                    <Col
                      lg={{ size: "6", offset: "3" }}
                      md={{ size: "6", offset: "3" }}
                      sm="12"
                    >
                      <Card className="p-3">
                        {typeof user.userid !== "undefined" && (
                          <Row className="mt-2">
                            <Col lg="12" md="12" sm="12">
                              <PathArea
                                pathType={pathType}
                                setPathType={setPathType}
                                user={user}
                              />
                            </Col>
                          </Row>
                        )}
                        <Book />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Grid>
            </Col>
          </Row>
        </>
      )}
      {pathType === "search" && (
        <>
          <Grid className="imgBackground" ref={cardRef}>
            <div className="fullWidthHomeSearch m-3 mb-6">
              <h1
                className="text-center"
                style={{ marginTop: "0.5rem", color: "black" }}
              >
                BUY OR SELL SEATS ON PRIVATE PLANES
              </h1>
              <Row>
                <Col
                  lg={{ size: "8", offset: "2" }}
                  md={{ size: "8", offset: "2" }}
                  sm="12"
                >
                  <Card
                    className="p-3 overflow-visible"
                    style={{ opacity: "1 !important" }}
                  >
                    {/* {typeof user.userid !== "undefined" && ( */}
                    <Row className="mt-2">
                      <Col lg="12" md="12" sm="12">
                        <PathArea
                          pathType={pathType}
                          user={user}
                          setPathType={setPathType}
                        />
                      </Col>
                    </Row>
                    {/* )} */}
                    <Search
                      unregistedFlightsData={unregistedFlightsData}
                      setFlightData={setFlightData}
                      setSearchClick={setSearchClick}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </Grid>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-3"></Col>
            {searchClick ? (
              <Col lg="12" md="12" sm="12" className="mb-3">
                <div className="sticky-component" ref={scrollToRef}>
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="#FFE600"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#FFE600",
                          height: 3,
                          textColor: "#FFE600",
                        },
                      }}
                    >
                      <Tab label="All" />
                      <Tab label="Seats" />
                      <Tab label="Charters" />
                      <Tab label="Interests" />
                    </Tabs>
                  </Box>
                </div>
                <FlightList
                  flightData={filteredData}
                  //setFlightData={setFilteredData}
                  page="Home"
                />
              </Col>
            ) : (
              <>
                <CardComponent />
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
}

export default HomePage;
