import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import {
  CardHeader,
  Row,
  Col,
  Input,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useValidateData } from "../../data/Validation";
import { useFriendsData } from "../../data/FrindsData";
import { useLocalStorage } from "../../utils/useLocalStorage";
import ImportContactFromGoogle from "../GoogleContact/ImportContactFromGoogle";
import ImportContactFromOutlook from "../OutLookContact/ImportContactFromOutlook";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { FormControl } from "react-bootstrap";
import { FormControl, FormGroup } from "@material-ui/core";

function AddFriend({ friendData, user, SetUser }) {
  const history = useHistory();
  const { CheckUserByMobile } = useValidateData();
  const { AddFriend, InviteFriend } = useFriendsData();
  const [mode, SetMode] = useState("");
  const [mobile, SetMobile] = useState("");
  const [name, SetName] = useState("");
  const [isFriend, SetIsFriend] = useState(false);
  const [isSelfNumber, SetIsSelfNumber] = useState(false);
  const [isAdded, SetAdded] = useState(false);
  const [isInvited, SetInvited] = useState(false);

  useEffect(() => {
    if (mobile.length >= 11) {
      SetUserMode();
    } else {
      SetMode("");
    }
  }, [mobile]);

  async function SetUserMode() {
    debugger;
    if (AlredyFriend()) {
      SetIsFriend(true);
    } else if (mobile == user.mobile) {
      SetIsSelfNumber(true);
    } else {
      const isUser = await CheckUserByMobile(mobile);
      if (isUser) {
        SetMode("Add");
      } else {
        SetMode("Invite");
      }
    }
  }

  function AlredyFriend() {
    const isFriend = friendData.filter(
      (x) => !x.is_group && x.mobile.toString() === mobile
    );
    if (isFriend.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async function ClickHandler(event) {
    const Body = {
      mobile: user.mobile,
      invmobile: parseInt(mobile),
      inviteename: user.name,
      friend_name: name,
    };

    if (mode === "Add") {
      const response = await AddFriend(Body);
      SetAdded(true);
      SetMode("");
      SetMobile("");
    } else if (mode === "Invite") {
      const response = await InviteFriend(Body);
      SetInvited(true);
      SetMode("");
      SetMobile("");
    }
  }

  return (
    <>
      {isFriend && (
        <Snackbar
          open={isFriend}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetIsFriend(!isFriend)}
        >
          <Alert className="m-2 alertYellowInverse">
            User already in your friend list, Try another number
          </Alert>
        </Snackbar>
      )}
      {isAdded && (
        <Snackbar
          open={isAdded}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetAdded(!isAdded)}
        >
          <Alert className="m-2 alertYellowInverse">
            Your friend request send successfully
          </Alert>
        </Snackbar>
      )}
      {isSelfNumber && (
        <Snackbar
          open={isSelfNumber}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetIsSelfNumber(!isSelfNumber)}
        >
          <Alert className="m-2 alertYellowInverse">
            You entered your own number, Try another number
          </Alert>
        </Snackbar>
      )}

      {isInvited && (
        <Snackbar
          open={isInvited}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetInvited(!isInvited)}
        >
          <Alert className="m-2 alertYellowInverse">
            Friend invited successfully
          </Alert>
        </Snackbar>
      )}
      <CardHeader className="border-0">
        <Row>
          <Col lg="3" md="4" sm="12" className="mt-3">
            <p style={{ color: "black", marginBottom: "0" }}>
              Enter your friend's mobile to send friend request.
            </p>
            {/* <InputGroup
              className="input-group-alternative"
              style={{ width: "100%" }}
            > */}
            {/* <FormGroup fullWidth> */}
            {/* <FormControl fullWidth> */}
            <PhoneInput
              country={"us"}
              value={mobile}
              onChange={(phone) => {
                console.log(phone.length);
                if (phone.length >= 11) SetMobile(phone);
              }}
            />
            {/* </FormControl> */}
            {/* </FormGroup> */}
            {/* <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-phone" />
                  &nbsp;&nbsp;&nbsp;(+1)
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                placeholder="Enter Friend Mobile"
                value={mobile}
                onChange={(e) => {
                  e.target.value.length < 11 && SetMobile(e.target.value);
                }}
              /> */}
            {/* </InputGroup> */}
          </Col>
          <Col lg="2" md="3" sm="12" className="mt-3">
            {mode === "Add" ? (
              <Button color="dark" onClick={(e) => ClickHandler(e)}>
                Add Friend
              </Button>
            ) : mode === "Invite" ? (
              <Button color="dark" onClick={(e) => ClickHandler(e)}>
                Invite Friend
              </Button>
            ) : (
              ""
            )}
          </Col>
          <Col lg="7" md="12" sm="12" className="text-right">
            <Row>
              <Col lg="6" md="6" sm="6" className="mt-3">
                <ImportContactFromGoogle
                  user={user}
                  SetUser={SetUser}
                  SetMobile={SetMobile}
                  SetName={SetName}
                  page="myFriend"
                />
              </Col>
              <Col lg="6" md="6" sm="6" className="mt-3">
                <ImportContactFromOutlook
                  user={user}
                  SetUser={SetUser}
                  SetMobile={SetMobile}
                  SetName={SetName}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
}

export default AddFriend;
