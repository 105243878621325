import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(utc);
dayjs.extend(customParseFormat);

// abcd

export function dateDisplay(givenDate, includeTodayPrefix) {
  const currentDate = dayjs();
  const dayStart = currentDate.startOf('day');
  const parsedDate = dayjs(givenDate);

  if (parsedDate.isAfter(dayStart) && includeTodayPrefix) {
    return "today " + parsedDate.format("hh:mm A");
  } else if (parsedDate.isAfter(dayStart)) {
    return parsedDate.format("hh:mm A");
  } else if (parsedDate.isAfter(dayStart.subtract(1, 'day'))) {
    return "yesterday " + parsedDate.format("h:mm A");
  } else {
    return parsedDate.format(`MM/DD/YY  h:mm A`);
  }
}





