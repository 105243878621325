import axios from "../libs/axios";

const AllUnregFlights = async () => {
  const { data } = await axios.request({
    url: "/GetHomePageFlightsUnregistered",
    method: "post",
    data: {},
  });
  return data;
};

const UnregSearchFlights = async (searchData) => {
  const { data } = await axios.request({
    url: "/SearchFlightsUnregistered",
    method: "post",
    data: searchData,
  });
  return data;
};

const UnregveryFlexibleSearchFlights = async (searchData) => {
  const { data } = await axios.request({
    url: "/SearchFlightsUnregisteredVeryFlexible",
    method: "post",
    data: searchData,
  });
  return data;
};

const allRegFlights = async (userid) => {
  const { data } = await axios.request({
    url: "/GetHomePageFlights",
    method: "post",
    data: {
      userid: parseInt(userid),
    },
  });
  console.log(data);
  return data;
};

const searchFlights = async (searchData) => {
  const { data } = await axios.request({
    url: "/Searchflights",
    method: "post",
    data: searchData,
  });
  return data;
};

const searchFlyEasyFlights = async (searchData) => {
  var flyEasyData;
  if (searchData.dep_date) {
    const today = new Date(searchData.dep_date);
    // Calculate the dates 2 days before and after today
    const twoDaysBefore = new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000);
    const twoDaysAfter = new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);

    // Format the dates as YYYY-MM-DD strings
    const startDate = twoDaysBefore.toISOString().split("T")[0];
    const endDate = twoDaysAfter.toISOString().split("T")[0];

    console.log(startDate, endDate); // Output: "2023-03-14" "2023-03-20"

    flyEasyData = {
      from_airport: searchData.from_icao,
      to_airport: searchData.to_icao,
      radiusNM: 50,
      dateRange: startDate + ":" + endDate,
      //apiKey: process.env.REACT_APP_FLYEASY_KEY
    };
  } else {
    flyEasyData = {
      from_airport: searchData.from_icao,
      to_airport: searchData.to_icao,
      radiusNM: 50,
      //apiKey: process.env.REACT_APP_FLYEASY_KEY
    };
  }
  const { data } = await axios.request({
    url: "/SearchFlyEasyFlights",
    method: "post",
    data: flyEasyData,
  });
  return data;
};

const searchVolatoCharters = async (searchData) => {
  var volatoData = {
    from_airport: searchData.from_icao,
    to_airport: searchData.to_icao,
    dep_date: searchData.dep_date,
  }
  const { data } = await axios.request({
    url: "/SearchVolatoCharters",
    method: "post",
    data: volatoData,
  });
  return data;
};

const veryFlexibleSearchFlights = async (searchData) => {
  const { data } = await axios.request({
    url: "/SearchFlightsVeryFlexible",
    method: "post",
    data: searchData,
  });
  return data;
};

const getFlightDetail = async (id) => {
  const { data } = await axios.request({
    url: "/GetFlightInfoWeb",
    method: "post",
    data: {
      flightno: parseInt(id, 10),
    },
  });
  return data;
};

const getMyFlights = async (mobile) => {
  const { data } = await axios.request({
    url: "/GetMyFlights",
    method: "post",
    data: {
      mobile: parseInt(mobile, 10),
    },
  });
  return data;
};

const postFlight = async (searchData) => {
  const { data } = await axios.request({
    url: "/PostFlight",
    method: "post",
    data: searchData,
  });
  return data;
};

const deleteFlight = async (mobile, id) => {
  const { data } = await axios.request({
    url: "/DeleteFlight",
    method: "post",
    data: { mobile: mobile, flightno: id },
  });
  return data;
};
const addFlight = async (flightData) => {
  const { data } = await axios.request({
    url: "/AddFlight",
    method: "post",
    data: flightData,
  });
  return data;
};

const joinFlightApi = async (flightData) => {
  const { data } = await axios.request({
    url: "/JoinFlight",
    method: "post",
    data: flightData,
  });
  return data;
};

const GetBulletin = async () => {
  const { data } = await axios.request({
    url: "/GetBulletin",
    method: "post",
    data: {},
  });
  return data;
};

const FlightPrice = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/BookFlightPrice",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const GetMyFlightPrice = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/MyFlightGetPrice",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const UpdateFlightData = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/EditMyFlight",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const FlightClickTrack = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/FlightClickTrack",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const getNearByAirport = async (location) => {
  const { data } = await axios.request({
    url:
      "/getNearbyAirports?lat=" +
      location.latitude +
      "&lng=" +
      location.longitude,
    method: "get",
  });
  return data;
};

export const useFlightData = () => ({
  AllUnregFlights,
  UnregSearchFlights,
  UnregveryFlexibleSearchFlights,
  allRegFlights,
  searchFlights,
  searchFlyEasyFlights,
  searchVolatoCharters,
  veryFlexibleSearchFlights,
  getFlightDetail,
  getMyFlights,
  postFlight,
  deleteFlight,
  addFlight,
  joinFlightApi,
  GetBulletin,
  FlightPrice,
  GetMyFlightPrice,
  UpdateFlightData,
  FlightClickTrack,
  getNearByAirport,
});
