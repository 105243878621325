import React, { useState, useEffect } from "react";
import { useOutlookData } from "../../utils/OutLookPeopleApi";
import { Button } from "reactstrap";
import ContactListModal from "./ContactListModal";

function ImportContactFromOutlook({ user, SetUser, SetMobile, SetName }) {
     const [isAuthorized, SetAuthorized] = useState(false);
     const [contactList, SetContactList] = useState([]);
     const [filterList, SetFilterList] = useState([]);
     const [searchInput, SetSearch] = useState("");
     const [modal, setModal] = useState(false);

    const { Authorize, GetContactListByUserId, SignOutOutlook } =
      useOutlookData();

    useEffect(() => {
      if (user.is_outlook_linked) {
        if (searchInput && searchInput !== "") {
          SearchData();
        } else {
            SetFilterList(contactList);
        }
      }
    }, [searchInput]);

    function SearchData(){

    }

    useEffect(() => {
      if (user.is_outlook_linked) {
        SetAuthorized(true);
      }
    }, []);

    function GetOutlookContact(e){
      GetContactsByUserId();
    }

    async function GetContactsByUserId() {
      const response = await GetContactListByUserId(user.userid);
      if (response.status) {
        let ContactListDetails = [];

        if (response.value.length > 0) {
          const connections = response.value;
          for (var i = 0; i < connections.length; i++) {
            const person = connections[i];
            console.log(person);
            // displayName;
            //mobilePhone
            if (
              person.displayName &&
              person.displayName.length > 0 &&
              person.mobilePhone &&
              person.mobilePhone.length > 0
            ) {
              let contact = {};
               contact.id = i;
               contact.name = person.displayName;
               if (person.mobilePhone.length === 10){
                 contact.phone = person.mobilePhone;
               }else{
                 var conicalForm = person.mobilePhone.split(" ").join("");
                 contact.phone = conicalForm.substring(conicalForm.length - 10);
               }
               ContactListDetails.push(contact);
            }
          }
        }
        SetContactList(ContactListDetails);
        SetFilterList(ContactListDetails);
        if (ContactListDetails.length > 0) {
          setModal(true);
        }
      } else {
        let userDetails = user;
          userDetails.is_outlook_linked = false;
          SetUser(userDetails);
          SetAuthorized(false);
      }
    
    }

    async function SignOut(e){
      const response = await SignOutOutlook(user.userid);
      if (response) {
        SetAuthorized(false);
        let userDetails = user;
        userDetails.is_outlook_linked = false;
        SetUser(userDetails);
      }
    }

    return (
      <>
        {!isAuthorized && (
          <div className="text-center">
            <strong Style={{ display: "inline-block", verticalAlign: "1em" }}>
              Sign In with:&nbsp;&nbsp;
            </strong>
            <Button
              onClick={Authorize}
              style={{
                borderRadius: "5px",
                backgroundColor: "#FFFFFF",
                borderRight: "1px solid lightgray",
                borderLeft: "1px solid lightgray",
                borderBottom: "3px solid lightgray",
                color: "lightgray !important",
              }}
              className="btn btn-secondary"
            >
              <img
                alt="..."
                style={{ height: "20px", width: "20px" }}
                src={require("../../assets/images/outlook.png").default}
              />
              &nbsp;&nbsp;&nbsp;
              <b style={{ color: "gray", fontWeight: "600" }}>Outlook</b>
            </Button>
          </div>
        )}
        {isAuthorized && (
          <div style={{ display: "flex" }}>
            <Button
              style={{
                width: "50%",
                borderRadius: "5px",
                backgroundColor: "#FFFFFF",
                borderRight: "1px solid lightgray",
                borderLeft: "1px solid lightgray",
                borderBottom: "3px solid lightgray",
                color: "lightgray !important",
              }}
              className="btn btn-secondary"
              onClick={GetOutlookContact}
            >
              {/* <img
                alt="..."
                style={{ height: "20px", width: "20px" }}
                src={require("../../assets/images/outlook.png").default}
              />
              &nbsp;&nbsp;&nbsp;Contact List */}
              Outlook Contacts
            </Button>
            <Button
              style={{
                width: "50%",
                borderRadius: "5px",
                backgroundColor: "#FFFFFF",
                borderRight: "1px solid lightgray",
                borderLeft: "1px solid lightgray",
                borderBottom: "3px solid lightgray",
                color: "lightgray !important",
              }}
              className="btn btn-secondary"
              onClick={SignOut}
            >
              {/* <img
                alt="..."
                style={{ height: "20px", width: "20px" }}
                src={require("../../assets/images/outlook.png").default}
              />
              &nbsp;&nbsp;&nbsp;Sign Out */}
              De-link Outlook
            </Button>
          </div>
        )}
        <ContactListModal
          modal={modal}
          setModal={setModal}
          contactList={filterList}
          SetMobile={SetMobile}
          SetName={SetName}
          search={searchInput}
          SetSearch={SetSearch}
        />
      </>
    );
}

export default ImportContactFromOutlook;