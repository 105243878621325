import "date-fns";
import React,{ useEffect, useState } from "react";
import {useHistory} from 'react-router-dom'
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  FormGroup, 
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";

import { Col, Row, Button, Alert } from "reactstrap";
import { useFlightData } from "../../data/FlightData";
import City from "../City";
import airportData from "../../data/airport";
import { useLocalStorage } from "../../utils/useLocalStorage";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SwapImage from "../../assets/images/icons/swap.png";
import { FaTimes } from "react-icons/fa";

function EditSearch(flightdata) {
  console.log(flightdata);
  const flightDetails = flightdata.flightdata[0];
    const [from, setFrom] = useState(flightDetails.from_airport);
    const [to, setTo] = useState(flightDetails.to_airport);
    const [datevalue, setDateValue] = useState("");
    const [formatteddatevalue, setFormattedDateValue] = useState("");
    const [passenger, setPassenger] = useState(flightDetails.passenger);
    const [pettype, SetPetType] = useState(flightDetails.pet_type);
    const [friend, setFriend] = useState("No");
    const [user] = useLocalStorage("auth", {});
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {UpdateFlightData} = useFlightData();
    const history = useHistory();

    useEffect(() => {
      setDateValue(flightDetails.dep_date);
      setFormattedDateValue(getFormatedDate(flightDetails.dep_date));
    }, []);

    const DateChange = (date) => {
      setFormattedDateValue(getFormatedDate(date));
      setDateValue(date);
    };

    function SwapCity(e) {
      const From = from;
      setFrom(to);
      setTo(From);
    }

    function getFormatedDate(strDate) {
      let date = new Date(strDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return month + "/" + dt + "/" + year;
    }

    async function EditFlightDetails() {
      let postData = {
        flightid: flightDetails.id,
        is_posted: flightDetails.is_posted,
        userid: user.userid,
        dep_date: formatteddatevalue,
        from_city: getCityFromCode(from),
        from_airport: from,
        to_city: getCityFromCode(to),
        to_airport: to,
        passenger: parseInt(passenger === "" ? "0" : passenger),
        is_pet: pettype === "No" ? false : true,
        pet_type: pettype,
      };

      if (ValidateData()) {
        console.log(postData);
        const response = await UpdateFlightData(postData);
        if (response.status) {
          history.push("/myFlights");
        }
      }
    }

    function getCityFromCode(code) {
      if (code) return airportData.find((item) => item.IATA === code).city;
      else return null;
    }

    function ValidateData() {
      if (from === "" || from === undefined || from === null) {
        setErrorMessage("Please select from");
        setIsError(true);
        return false;
      } else if (to === "" || to === undefined || to === null) {
        setErrorMessage("Please select to");
        setIsError(true);
        return false;
      } else if (
        formatteddatevalue === "" ||
        formatteddatevalue === undefined ||
        formatteddatevalue === null
      ) {
        setErrorMessage("Please select valid date");
        setIsError(true);
        return false;
      } else if (
        passenger === "" ||
        passenger === undefined ||
        passenger === null ||
        passenger < 1
      ) {
        setErrorMessage("Please select passenger");
        setIsError(true);
        return false;
      } else {
        setErrorMessage("");
        setIsError(false);
        return true;
      }
    }

    return (
      <>
        {isError && (
          <Snackbar
            open={isError}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            onClose={(e) => setIsError(!isError)}
          >
            <Alert color="danger">{errorMessage}</Alert>
          </Snackbar>
        )}
        <Row>
          <Col lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }} sm="12">
            <Row className="pl-2 pr-1 mt-2">
              <Col lg="12" md="12" sm="12">
                <City type="from" code={from} setCode={setFrom} />
              </Col>
              <Col lg="12" md="12" sm="12">
                <img src={SwapImage} className="btnSwap" onClick={SwapCity} />
              </Col>
              <Col lg="12" md="12" sm="12">
                <City type="to" code={to} setCode={setTo} />
              </Col>
              <Col lg="12" md="12" sm="12" className="mb-3">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="p-0"
                    fullWidth
                    label="Date"
                    minDate={new Date().toString()}
                    format="MM/dd/yyyy"
                    value={datevalue}
                    onChange={DateChange}
                    color="rgb(255, 230, 0)"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    keyboardIcon={
                      <img
                        alt="..."
                        style={{ height: "25px" }}
                        src={
                          require("../../assets/images/icons/calendar.png")
                            .default
                        }
                      />
                    }
                  />
                </MuiPickersUtilsProvider>
                {datevalue && (
                  <FaTimes
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setDateValue(null);
                      setFormattedDateValue(null);
                    }}
                  />
                )}
              </Col>
              <Col lg="6" md="6" sm="6" xs="6">
                <FormGroup className="mb-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="passengers">Passengers</InputLabel>
                    <Select
                      id="passengers"
                      value={passenger}
                      onChange={(e) => setPassenger(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start">
                          <img
                            alt="..."
                            style={{ height: "25px" }}
                            src={
                              require("../../assets/images/icons/passengers.png")
                                .default
                            }
                          />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="6" xs="6">
                {/* <FilterPet pet={pet} setPet={setPet} /> */}
                <FormGroup className="mb-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="pet">Pet</InputLabel>
                    <Select
                      id="pet"
                      value={pettype}
                      onChange={(e) =>
                        SetPetType(e.target.value == "" ? "No" : e.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <img
                            alt="..."
                            style={{ height: "25px" }}
                            src={
                              require("../../assets/images/icons/pet.png")
                                .default
                            }
                          />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="No">No</MenuItem>
                      <MenuItem value="Cat">Cat</MenuItem>
                      <MenuItem value="Dog">Dog</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            <Row className="text-center px-0 py-2">
              <Col lg="12" md="12" sm="12">
                <Button
                  style={{ borderRadius: "5px" }}
                  className="btn btn-block btnYellow"
                  onClick={(e) => EditFlightDetails()}
                >
                  Update Details
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
}

export default EditSearch
